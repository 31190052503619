import SvgIcon from '@mui/joy/SvgIcon'
import { SxProps } from '@mui/material'
import * as React from 'react'

export function BlackFullIcon() {
  return (
    <SvgIcon viewBox="0 0 260 45" sx={{ width: '6.5em' }}>
      <path
        d="M64.5183 12.38V35.09H60.2383V12.38H53.2383V8.73999H71.4883V12.38H64.5183Z"
        fill="black"
      />
      <path
        d="M75.3375 20.7499C75.7575 19.6199 76.3076 18.7899 76.9976 18.2499C77.6876 17.7099 78.5375 17.4399 79.5675 17.4399C80.1175 17.4399 80.6575 17.5099 81.1975 17.6399C81.7275 17.7699 82.2576 17.9599 82.7676 18.2099L81.3676 21.5199C81.0276 21.2799 80.6676 21.0999 80.2776 20.9899C79.8876 20.8799 79.4575 20.8199 78.9775 20.8199C77.6775 20.8199 76.7475 21.3399 76.1875 22.3799C75.6275 23.4199 75.3475 25.1599 75.3475 27.6099V35.0999H71.4375V17.9999H75.3475L75.3375 20.7499Z"
        fill="black"
      />
      <path
        d="M101.047 35.0899H97.1466V32.8799C96.4466 33.8599 95.6365 34.5999 94.7065 35.0799C93.7765 35.5599 92.7066 35.7999 91.5066 35.7999C89.0966 35.7999 87.1965 34.9799 85.8065 33.3299C84.4265 31.6799 83.7266 29.4199 83.7266 26.5499C83.7266 23.8299 84.4366 21.6299 85.8466 19.9499C87.2666 18.2799 89.1066 17.4399 91.3866 17.4399C92.7766 17.4399 93.9665 17.7299 94.9565 18.3099C95.9465 18.8899 96.6666 19.7299 97.1266 20.8199V18.0299H101.027V35.0899H101.047ZM97.2466 26.5099C97.2466 24.7499 96.8366 23.3699 96.0166 22.3899C95.1966 21.4099 94.0465 20.9099 92.5765 20.9099C91.1065 20.9099 89.9366 21.4199 89.0966 22.4299C88.2466 23.4399 87.8265 24.8499 87.8265 26.6499C87.8265 28.4599 88.2365 29.8599 89.0565 30.8399C89.8765 31.8199 91.0266 32.3199 92.5166 32.3199C93.9166 32.3199 95.0565 31.7899 95.9465 30.7199C96.8065 29.6499 97.2466 28.2399 97.2466 26.5099Z"
        fill="black"
      />
      <path
        d="M118.6 7.19995H122.5V35.09H118.6V32.88C117.9 33.86 117.09 34.6 116.16 35.08C115.23 35.56 114.16 35.8 112.96 35.8C110.55 35.8 108.65 34.98 107.26 33.33C105.88 31.68 105.18 29.42 105.18 26.55C105.18 23.83 105.89 21.63 107.3 19.95C108.72 18.28 110.56 17.44 112.84 17.44C114.23 17.44 115.42 17.73 116.41 18.31C117.4 18.89 118.12 19.7299 118.58 20.8199V7.19995H118.6ZM118.71 26.5099C118.71 24.7499 118.3 23.37 117.48 22.39C116.66 21.41 115.51 20.91 114.04 20.91C112.57 20.91 111.4 21.4199 110.56 22.4299C109.71 23.4399 109.29 24.8499 109.29 26.6499C109.29 28.4599 109.7 29.86 110.52 30.84C111.34 31.82 112.49 32.3199 113.98 32.3199C115.4 32.3199 116.54 31.7899 117.42 30.7299C118.27 29.66 118.71 28.2599 118.71 26.5099Z"
        fill="black"
      />
      <path
        d="M144.327 27.1399H130.687C130.787 28.7299 131.257 29.9799 132.107 30.8699C132.957 31.7599 134.087 32.2099 135.507 32.2099C136.767 32.2099 137.817 31.9499 138.657 31.4199C139.497 30.8899 140.227 30.0399 140.847 28.8599L144.147 30.6899C143.187 32.4099 141.997 33.6799 140.587 34.5199C139.177 35.3599 137.507 35.7799 135.597 35.7799C132.857 35.7799 130.687 34.9499 129.067 33.2799C127.447 31.6099 126.637 29.3899 126.637 26.5999C126.637 23.9199 127.477 21.7199 129.147 19.9999C130.827 18.2799 132.977 17.4199 135.597 17.4199C138.347 17.4199 140.487 18.1999 142.027 19.7799C143.567 21.3499 144.327 23.5499 144.327 26.3899V27.1399ZM140.287 24.5099C140.127 23.2899 139.667 22.3499 138.887 21.7099C138.107 21.0699 137.037 20.7499 135.677 20.7499C134.397 20.7499 133.357 21.0599 132.587 21.6899C131.807 22.3199 131.257 23.2599 130.947 24.5199H140.287V24.5099Z"
        fill="black"
      />
      <path
        d="M148.939 35.09V21.45H145.949V18.03H148.939V13.46C148.939 11.19 149.409 9.51996 150.349 8.43996C151.289 7.35996 152.749 6.81995 154.729 6.81995C155.149 6.81995 155.579 6.84996 156.029 6.90996C156.479 6.96996 156.919 7.05995 157.379 7.19995V10.7299C157.069 10.5499 156.749 10.4099 156.429 10.3199C156.109 10.2299 155.789 10.1799 155.469 10.1799C154.539 10.1799 153.859 10.47 153.459 11.05C153.049 11.62 152.849 12.6 152.849 13.97V18.0399H156.949V21.46H152.849V35.1H148.939V35.09Z"
        fill="black"
      />
      <path
        d="M163.468 18.0299V27.6399C163.468 29.2999 163.808 30.4799 164.498 31.1999C165.178 31.9199 166.288 32.2799 167.828 32.2799C169.368 32.2799 170.478 31.9199 171.158 31.1999C171.848 30.4799 172.188 29.2899 172.188 27.6399V18.0299H176.098V26.4699C176.098 29.7299 175.448 32.0999 174.138 33.5799C172.828 35.0599 170.748 35.7899 167.878 35.7899C164.958 35.7899 162.848 35.0599 161.538 33.5999C160.228 32.1299 159.578 29.7599 159.578 26.4699V18.0299H163.468Z"
        fill="black"
      />
      <path d="M180.477 35.09V7.19995H184.377V35.09H180.477Z" fill="black" />
      <path
        d="M189.969 35.0899V18.0299H193.639V20.8199C194.419 19.6499 195.339 18.7899 196.399 18.2499C197.459 17.7099 198.739 17.4399 200.249 17.4399C201.519 17.4399 202.599 17.6399 203.499 18.0399C204.389 18.4399 205.089 19.0399 205.599 19.8299C205.899 20.2999 206.119 20.8399 206.249 21.4699C206.379 22.0899 206.449 23.1899 206.449 24.7399V35.0899H202.509V26.3299C202.509 24.0899 202.239 22.5999 201.689 21.8599C201.139 21.1199 200.179 20.7499 198.779 20.7499C197.869 20.7499 197.049 20.9199 196.339 21.2499C195.619 21.5899 195.059 22.0599 194.659 22.6699C194.369 23.0799 194.169 23.5999 194.059 24.2499C193.949 24.8899 193.889 25.9099 193.889 27.3199V35.0899H189.969Z"
        fill="black"
      />
      <path
        d="M228.068 27.1399H214.428C214.528 28.7299 214.998 29.9799 215.858 30.8699C216.708 31.7599 217.838 32.2099 219.258 32.2099C220.518 32.2099 221.568 31.9499 222.408 31.4199C223.248 30.8899 223.978 30.0399 224.608 28.8599L227.908 30.6899C226.948 32.4099 225.758 33.6799 224.338 34.5199C222.918 35.3599 221.268 35.7799 219.358 35.7799C216.618 35.7799 214.438 34.9499 212.828 33.2799C211.208 31.6099 210.398 29.3899 210.398 26.5999C210.398 23.9199 211.238 21.7199 212.908 19.9999C214.578 18.2799 216.728 17.4199 219.358 17.4199C222.108 17.4199 224.248 18.1999 225.788 19.7799C227.328 21.3499 228.088 23.5499 228.088 26.3899V27.1399H228.068ZM224.018 24.5099C223.858 23.2899 223.398 22.3499 222.618 21.7099C221.838 21.0699 220.778 20.7499 219.418 20.7499C218.128 20.7499 217.098 21.0599 216.318 21.6899C215.538 22.3199 214.988 23.2599 214.678 24.5199H224.018V24.5099Z"
        fill="black"
      />
      <path
        d="M230.168 31.9899L233.408 30.2299C233.648 31.0799 234.088 31.7299 234.738 32.1699C235.378 32.6199 236.218 32.8399 237.238 32.8399C238.118 32.8399 238.808 32.6399 239.298 32.2399C239.798 31.8399 240.048 31.2899 240.048 30.5899C240.048 29.6699 239.158 28.8699 237.388 28.2099C236.788 27.9899 236.328 27.8099 236.008 27.6699C234.188 26.9199 232.908 26.1499 232.178 25.3699C231.448 24.5899 231.088 23.6399 231.088 22.5299C231.088 21.0099 231.688 19.7699 232.878 18.8199C234.078 17.8699 235.648 17.3999 237.618 17.3999C238.938 17.3999 240.068 17.6399 241.008 18.1299C241.948 18.6199 242.638 19.3099 243.068 20.2099L240.298 22.1199C240.098 21.5799 239.738 21.1499 239.228 20.8299C238.718 20.5099 238.128 20.3499 237.458 20.3499C236.718 20.3499 236.118 20.5299 235.668 20.8799C235.218 21.2299 234.998 21.6999 234.998 22.2899C234.998 23.1799 236.008 23.9899 238.018 24.7199C238.658 24.9499 239.148 25.1299 239.498 25.2799C241.068 25.8999 242.198 26.6099 242.868 27.3899C243.548 28.1799 243.888 29.1599 243.888 30.3299C243.888 32.0299 243.268 33.3699 242.038 34.3299C240.808 35.2899 239.098 35.7799 236.928 35.7799C235.258 35.7799 233.878 35.4699 232.768 34.8399C231.658 34.2299 230.798 33.2799 230.168 31.9899Z"
        fill="black"
      />
      <path
        d="M245.867 31.9899L249.107 30.2299C249.347 31.0799 249.787 31.7299 250.437 32.1699C251.077 32.6199 251.917 32.8399 252.937 32.8399C253.817 32.8399 254.497 32.6399 254.997 32.2399C255.497 31.8399 255.747 31.2899 255.747 30.5899C255.747 29.6699 254.857 28.8699 253.087 28.2099C252.487 27.9899 252.027 27.8099 251.707 27.6699C249.887 26.9199 248.607 26.1499 247.877 25.3699C247.147 24.5899 246.787 23.6399 246.787 22.5299C246.787 21.0099 247.387 19.7699 248.577 18.8199C249.777 17.8699 251.347 17.3999 253.317 17.3999C254.637 17.3999 255.767 17.6399 256.707 18.1299C257.647 18.6199 258.337 19.3099 258.767 20.2099L255.997 22.1199C255.797 21.5799 255.437 21.1499 254.927 20.8299C254.417 20.5099 253.827 20.3499 253.157 20.3499C252.417 20.3499 251.817 20.5299 251.367 20.8799C250.917 21.2299 250.697 21.6999 250.697 22.2899C250.697 23.1799 251.707 23.9899 253.717 24.7199C254.357 24.9499 254.847 25.1299 255.197 25.2799C256.767 25.8999 257.897 26.6099 258.567 27.3899C259.247 28.1799 259.587 29.1599 259.587 30.3299C259.587 32.0299 258.967 33.3699 257.737 34.3299C256.507 35.2899 254.797 35.7799 252.627 35.7799C250.957 35.7799 249.577 35.4699 248.467 34.8399C247.347 34.2299 246.487 33.2799 245.867 31.9899Z"
        fill="black"
      />
      <path
        d="M14.6916 29.14H10.3516C10.3516 23.73 14.8916 19.32 20.4716 19.32H22.5416C25.7316 19.32 28.3216 16.86 28.3216 13.84H32.6616C32.6616 19.25 28.1216 23.66 22.5416 23.66H20.4716C17.2816 23.67 14.6916 26.12 14.6916 29.14Z"
        fill="black"
      />
      <path
        d="M21.5 43C9.65 43 0 33.36 0 21.5C0 9.64 9.64 0 21.5 0C33.36 0 43 9.64 43 21.5C43 33.35 33.36 43 21.5 43ZM21.5 5.21001C12.52 5.21001 5.21002 12.52 5.21002 21.5C5.21002 30.48 12.52 37.79 21.5 37.79C30.48 37.79 37.79 30.48 37.79 21.5C37.79 12.52 30.48 5.21001 21.5 5.21001Z"
        fill="black"
      />
    </SvgIcon>
  )
}

export function WhiteFullIcon() {
  return (
    <SvgIcon viewBox="0 0 260 45" sx={{ width: '6.5em' }}>
      <path
        d="M64.5183 12.38V35.09H60.2383V12.38H53.2383V8.73999H71.4883V12.38H64.5183Z"
        fill="white"
      />
      <path
        d="M75.3375 20.7499C75.7575 19.6199 76.3076 18.7899 76.9976 18.2499C77.6876 17.7099 78.5375 17.4399 79.5675 17.4399C80.1175 17.4399 80.6575 17.5099 81.1975 17.6399C81.7275 17.7699 82.2576 17.9599 82.7676 18.2099L81.3676 21.5199C81.0276 21.2799 80.6676 21.0999 80.2776 20.9899C79.8876 20.8799 79.4575 20.8199 78.9775 20.8199C77.6775 20.8199 76.7475 21.3399 76.1875 22.3799C75.6275 23.4199 75.3475 25.1599 75.3475 27.6099V35.0999H71.4375V17.9999H75.3475L75.3375 20.7499Z"
        fill="white"
      />
      <path
        d="M101.047 35.0899H97.1466V32.8799C96.4466 33.8599 95.6365 34.5999 94.7065 35.0799C93.7765 35.5599 92.7066 35.7999 91.5066 35.7999C89.0966 35.7999 87.1965 34.9799 85.8065 33.3299C84.4265 31.6799 83.7266 29.4199 83.7266 26.5499C83.7266 23.8299 84.4366 21.6299 85.8466 19.9499C87.2666 18.2799 89.1066 17.4399 91.3866 17.4399C92.7766 17.4399 93.9665 17.7299 94.9565 18.3099C95.9465 18.8899 96.6666 19.7299 97.1266 20.8199V18.0299H101.027V35.0899H101.047ZM97.2466 26.5099C97.2466 24.7499 96.8366 23.3699 96.0166 22.3899C95.1966 21.4099 94.0465 20.9099 92.5765 20.9099C91.1065 20.9099 89.9366 21.4199 89.0966 22.4299C88.2466 23.4399 87.8265 24.8499 87.8265 26.6499C87.8265 28.4599 88.2365 29.8599 89.0565 30.8399C89.8765 31.8199 91.0266 32.3199 92.5166 32.3199C93.9166 32.3199 95.0565 31.7899 95.9465 30.7199C96.8065 29.6499 97.2466 28.2399 97.2466 26.5099Z"
        fill="white"
      />
      <path
        d="M118.6 7.19995H122.5V35.09H118.6V32.88C117.9 33.86 117.09 34.6 116.16 35.08C115.23 35.56 114.16 35.8 112.96 35.8C110.55 35.8 108.65 34.98 107.26 33.33C105.88 31.68 105.18 29.42 105.18 26.55C105.18 23.83 105.89 21.63 107.3 19.95C108.72 18.28 110.56 17.44 112.84 17.44C114.23 17.44 115.42 17.73 116.41 18.31C117.4 18.89 118.12 19.7299 118.58 20.8199V7.19995H118.6ZM118.71 26.5099C118.71 24.7499 118.3 23.37 117.48 22.39C116.66 21.41 115.51 20.91 114.04 20.91C112.57 20.91 111.4 21.4199 110.56 22.4299C109.71 23.4399 109.29 24.8499 109.29 26.6499C109.29 28.4599 109.7 29.86 110.52 30.84C111.34 31.82 112.49 32.3199 113.98 32.3199C115.4 32.3199 116.54 31.7899 117.42 30.7299C118.27 29.66 118.71 28.2599 118.71 26.5099Z"
        fill="white"
      />
      <path
        d="M144.327 27.1399H130.687C130.787 28.7299 131.257 29.9799 132.107 30.8699C132.957 31.7599 134.087 32.2099 135.507 32.2099C136.767 32.2099 137.817 31.9499 138.657 31.4199C139.497 30.8899 140.227 30.0399 140.847 28.8599L144.147 30.6899C143.187 32.4099 141.997 33.6799 140.587 34.5199C139.177 35.3599 137.507 35.7799 135.597 35.7799C132.857 35.7799 130.687 34.9499 129.067 33.2799C127.447 31.6099 126.637 29.3899 126.637 26.5999C126.637 23.9199 127.477 21.7199 129.147 19.9999C130.827 18.2799 132.977 17.4199 135.597 17.4199C138.347 17.4199 140.487 18.1999 142.027 19.7799C143.567 21.3499 144.327 23.5499 144.327 26.3899V27.1399ZM140.287 24.5099C140.127 23.2899 139.667 22.3499 138.887 21.7099C138.107 21.0699 137.037 20.7499 135.677 20.7499C134.397 20.7499 133.357 21.0599 132.587 21.6899C131.807 22.3199 131.257 23.2599 130.947 24.5199H140.287V24.5099Z"
        fill="white"
      />
      <path
        d="M148.939 35.09V21.45H145.949V18.03H148.939V13.46C148.939 11.19 149.409 9.51996 150.349 8.43996C151.289 7.35996 152.749 6.81995 154.729 6.81995C155.149 6.81995 155.579 6.84996 156.029 6.90996C156.479 6.96996 156.919 7.05995 157.379 7.19995V10.7299C157.069 10.5499 156.749 10.4099 156.429 10.3199C156.109 10.2299 155.789 10.1799 155.469 10.1799C154.539 10.1799 153.859 10.47 153.459 11.05C153.049 11.62 152.849 12.6 152.849 13.97V18.0399H156.949V21.46H152.849V35.1H148.939V35.09Z"
        fill="white"
      />
      <path
        d="M163.468 18.0299V27.6399C163.468 29.2999 163.808 30.4799 164.498 31.1999C165.178 31.9199 166.288 32.2799 167.828 32.2799C169.368 32.2799 170.478 31.9199 171.158 31.1999C171.848 30.4799 172.188 29.2899 172.188 27.6399V18.0299H176.098V26.4699C176.098 29.7299 175.448 32.0999 174.138 33.5799C172.828 35.0599 170.748 35.7899 167.878 35.7899C164.958 35.7899 162.848 35.0599 161.538 33.5999C160.228 32.1299 159.578 29.7599 159.578 26.4699V18.0299H163.468Z"
        fill="white"
      />
      <path d="M180.477 35.09V7.19995H184.377V35.09H180.477Z" fill="white" />
      <path
        d="M189.969 35.0899V18.0299H193.639V20.8199C194.419 19.6499 195.339 18.7899 196.399 18.2499C197.459 17.7099 198.739 17.4399 200.249 17.4399C201.519 17.4399 202.599 17.6399 203.499 18.0399C204.389 18.4399 205.089 19.0399 205.599 19.8299C205.899 20.2999 206.119 20.8399 206.249 21.4699C206.379 22.0899 206.449 23.1899 206.449 24.7399V35.0899H202.509V26.3299C202.509 24.0899 202.239 22.5999 201.689 21.8599C201.139 21.1199 200.179 20.7499 198.779 20.7499C197.869 20.7499 197.049 20.9199 196.339 21.2499C195.619 21.5899 195.059 22.0599 194.659 22.6699C194.369 23.0799 194.169 23.5999 194.059 24.2499C193.949 24.8899 193.889 25.9099 193.889 27.3199V35.0899H189.969Z"
        fill="white"
      />
      <path
        d="M228.068 27.1399H214.428C214.528 28.7299 214.998 29.9799 215.858 30.8699C216.708 31.7599 217.838 32.2099 219.258 32.2099C220.518 32.2099 221.568 31.9499 222.408 31.4199C223.248 30.8899 223.978 30.0399 224.608 28.8599L227.908 30.6899C226.948 32.4099 225.758 33.6799 224.338 34.5199C222.918 35.3599 221.268 35.7799 219.358 35.7799C216.618 35.7799 214.438 34.9499 212.828 33.2799C211.208 31.6099 210.398 29.3899 210.398 26.5999C210.398 23.9199 211.238 21.7199 212.908 19.9999C214.578 18.2799 216.728 17.4199 219.358 17.4199C222.108 17.4199 224.248 18.1999 225.788 19.7799C227.328 21.3499 228.088 23.5499 228.088 26.3899V27.1399H228.068ZM224.018 24.5099C223.858 23.2899 223.398 22.3499 222.618 21.7099C221.838 21.0699 220.778 20.7499 219.418 20.7499C218.128 20.7499 217.098 21.0599 216.318 21.6899C215.538 22.3199 214.988 23.2599 214.678 24.5199H224.018V24.5099Z"
        fill="white"
      />
      <path
        d="M230.168 31.9899L233.408 30.2299C233.648 31.0799 234.088 31.7299 234.738 32.1699C235.378 32.6199 236.218 32.8399 237.238 32.8399C238.118 32.8399 238.808 32.6399 239.298 32.2399C239.798 31.8399 240.048 31.2899 240.048 30.5899C240.048 29.6699 239.158 28.8699 237.388 28.2099C236.788 27.9899 236.328 27.8099 236.008 27.6699C234.188 26.9199 232.908 26.1499 232.178 25.3699C231.448 24.5899 231.088 23.6399 231.088 22.5299C231.088 21.0099 231.688 19.7699 232.878 18.8199C234.078 17.8699 235.648 17.3999 237.618 17.3999C238.938 17.3999 240.068 17.6399 241.008 18.1299C241.948 18.6199 242.638 19.3099 243.068 20.2099L240.298 22.1199C240.098 21.5799 239.738 21.1499 239.228 20.8299C238.718 20.5099 238.128 20.3499 237.458 20.3499C236.718 20.3499 236.118 20.5299 235.668 20.8799C235.218 21.2299 234.998 21.6999 234.998 22.2899C234.998 23.1799 236.008 23.9899 238.018 24.7199C238.658 24.9499 239.148 25.1299 239.498 25.2799C241.068 25.8999 242.198 26.6099 242.868 27.3899C243.548 28.1799 243.888 29.1599 243.888 30.3299C243.888 32.0299 243.268 33.3699 242.038 34.3299C240.808 35.2899 239.098 35.7799 236.928 35.7799C235.258 35.7799 233.878 35.4699 232.768 34.8399C231.658 34.2299 230.798 33.2799 230.168 31.9899Z"
        fill="white"
      />
      <path
        d="M245.867 31.9899L249.107 30.2299C249.347 31.0799 249.787 31.7299 250.437 32.1699C251.077 32.6199 251.917 32.8399 252.937 32.8399C253.817 32.8399 254.497 32.6399 254.997 32.2399C255.497 31.8399 255.747 31.2899 255.747 30.5899C255.747 29.6699 254.857 28.8699 253.087 28.2099C252.487 27.9899 252.027 27.8099 251.707 27.6699C249.887 26.9199 248.607 26.1499 247.877 25.3699C247.147 24.5899 246.787 23.6399 246.787 22.5299C246.787 21.0099 247.387 19.7699 248.577 18.8199C249.777 17.8699 251.347 17.3999 253.317 17.3999C254.637 17.3999 255.767 17.6399 256.707 18.1299C257.647 18.6199 258.337 19.3099 258.767 20.2099L255.997 22.1199C255.797 21.5799 255.437 21.1499 254.927 20.8299C254.417 20.5099 253.827 20.3499 253.157 20.3499C252.417 20.3499 251.817 20.5299 251.367 20.8799C250.917 21.2299 250.697 21.6999 250.697 22.2899C250.697 23.1799 251.707 23.9899 253.717 24.7199C254.357 24.9499 254.847 25.1299 255.197 25.2799C256.767 25.8999 257.897 26.6099 258.567 27.3899C259.247 28.1799 259.587 29.1599 259.587 30.3299C259.587 32.0299 258.967 33.3699 257.737 34.3299C256.507 35.2899 254.797 35.7799 252.627 35.7799C250.957 35.7799 249.577 35.4699 248.467 34.8399C247.347 34.2299 246.487 33.2799 245.867 31.9899Z"
        fill="white"
      />
      <path
        d="M14.6916 29.14H10.3516C10.3516 23.73 14.8916 19.32 20.4716 19.32H22.5416C25.7316 19.32 28.3216 16.86 28.3216 13.84H32.6616C32.6616 19.25 28.1216 23.66 22.5416 23.66H20.4716C17.2816 23.67 14.6916 26.12 14.6916 29.14Z"
        fill="white"
      />
      <path
        d="M21.5 43C9.65 43 0 33.36 0 21.5C0 9.64 9.64 0 21.5 0C33.36 0 43 9.64 43 21.5C43 33.35 33.36 43 21.5 43ZM21.5 5.21001C12.52 5.21001 5.21002 12.52 5.21002 21.5C5.21002 30.48 12.52 37.79 21.5 37.79C30.48 37.79 37.79 30.48 37.79 21.5C37.79 12.52 30.48 5.21001 21.5 5.21001Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export function BlackSmallIcon() {
  return (
    <SvgIcon viewBox="0 0 100 100" sx={{ width: 24 }}>
      <path
        d="M33.7151 68.2683H23.3281C23.3281 55.3282 34.1908 44.7986 47.5273 44.7986H52.4591C60.0868 44.7986 66.2715 38.9312 66.2715 31.7158H76.6583C76.6583 44.6559 65.7957 55.1855 52.4591 55.1855H47.5273C39.9155 55.1855 33.7151 61.0529 33.7151 68.2683Z"
        fill="black"
      />
      <path
        d="M50.0001 100C22.4232 100 0 77.561 0 50C0 22.4389 22.4232 0 50.0001 0C77.577 0 100 22.4389 100 50C100 77.561 77.577 100 50.0001 100ZM50.0001 9.67329C27.7673 9.67329 9.6733 27.7514 9.6733 49.9842C9.6733 72.217 27.7673 90.295 50.0001 90.295C72.2329 90.295 90.3267 72.217 90.3267 49.9842C90.3267 27.7514 72.2329 9.67329 50.0001 9.67329Z"
        fill="black"
      />
    </SvgIcon>
  )
}

export function WhiteSmallIcon() {
  return (
    <SvgIcon viewBox="0 0 100 100">
      <path
        d="M33.715 68.2683H23.3281C23.3281 55.3282 34.1908 44.7986 47.5273 44.7986H52.4591C60.0867 44.7986 66.2714 38.9312 66.2714 31.7159H76.6583C76.6583 44.6559 65.7956 55.1855 52.4591 55.1855H47.5273C39.9155 55.1855 33.715 61.0529 33.715 68.2683Z"
        fill="white"
      />
      <path
        d="M50 100C22.4231 100 0 77.5612 0 50.0001C0 22.439 22.4231 0 50 0C77.5769 0 100 22.439 100 50.0001C100 77.5612 77.5769 100 50 100ZM50 9.6733C27.7672 9.6733 9.67334 27.7514 9.67334 49.9842C9.67334 72.217 27.7672 90.295 50 90.295C72.2328 90.295 90.3267 72.217 90.3267 49.9842C90.3267 27.7514 72.2328 9.6733 50 9.6733Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export function BlackFullVerticalWithTextIcon({ sx }: { sx?: SxProps }) {
  return (
    <SvgIcon viewBox="0 0 506 321" sx={sx}>
      <path
        d="M27.657 212.772V268.543H17.163V212.772H0V203.837H44.7464V212.772H27.657Z"
        fill="black"
      />
      <path
        d="M54.1951 233.318C55.2249 230.545 56.5734 228.507 58.2651 227.182C59.9569 225.856 62.041 225.193 64.5664 225.193C65.915 225.193 67.239 225.365 68.563 225.684C69.8625 226.003 71.162 226.47 72.4124 227.084L68.9798 235.209C68.1461 234.619 67.2635 234.178 66.3073 233.908C65.3511 233.637 64.2968 233.49 63.1199 233.49C59.9325 233.49 57.6522 234.767 56.2791 237.32C54.9061 239.872 54.2197 244.144 54.2197 250.158V268.543H44.6328V226.568H54.2197L54.1951 233.318Z"
        fill="black"
      />
      <path
        d="M117.255 268.518H107.693V263.093C105.977 265.499 103.991 267.315 101.711 268.493C99.4303 269.672 96.8068 270.261 93.8646 270.261C87.9556 270.261 83.2971 268.223 79.889 264.173C76.5054 260.123 74.7891 254.575 74.7891 247.53C74.7891 240.853 76.5299 235.453 80.0115 231.329C83.4932 227.23 88.0047 225.168 93.5949 225.168C97.003 225.168 99.9207 225.88 102.348 227.304C104.775 228.727 106.541 230.789 107.669 233.465V226.616H117.231L117.255 268.518ZM107.938 247.457C107.938 243.136 106.933 239.749 104.923 237.343C102.912 234.938 100.092 233.71 96.4881 233.71C92.8839 233.71 90.0151 234.962 87.9555 237.441C85.8715 239.921 84.8418 243.382 84.8418 247.8C84.8418 252.243 85.847 255.68 87.8575 258.086C89.868 260.491 92.6877 261.718 96.341 261.718C99.7736 261.718 102.593 260.418 104.751 257.791C106.859 255.165 107.938 251.728 107.938 247.457Z"
        fill="black"
      />
      <path
        d="M160.287 200.056H169.849V268.542H160.287V263.117C158.57 265.523 156.584 267.339 154.304 268.517C152.024 269.696 149.401 270.285 146.458 270.285C140.549 270.285 135.891 268.247 132.483 264.197C129.099 260.147 127.383 254.599 127.383 247.554C127.383 240.877 129.124 235.477 132.605 231.353C136.087 227.254 140.598 225.192 146.189 225.192C149.597 225.192 152.514 225.904 154.942 227.327C157.369 228.751 159.134 230.813 160.262 233.489L160.287 200.056ZM160.557 247.456C160.557 243.136 159.551 239.748 157.541 237.343C155.53 234.937 152.71 233.71 149.106 233.71C145.502 233.71 142.633 234.961 140.574 237.441C138.49 239.92 137.46 243.381 137.46 247.8C137.46 252.243 138.465 255.679 140.476 258.085C142.486 260.49 145.306 261.718 148.959 261.718C152.441 261.718 155.236 260.417 157.394 257.815C159.478 255.213 160.557 251.752 160.557 247.456Z"
        fill="black"
      />
      <path
        d="M223.381 249.003H189.938C190.183 252.906 191.335 255.974 193.419 258.184C195.503 260.368 198.299 261.473 201.756 261.473C204.845 261.473 207.419 260.835 209.479 259.534C211.539 258.233 213.328 256.146 214.849 253.25L222.94 257.742C220.586 261.964 217.668 265.081 214.211 267.143C210.754 269.205 206.659 270.236 201.976 270.236C195.258 270.236 189.938 268.199 185.966 264.099C181.994 260 180.008 254.551 180.008 247.702C180.008 241.124 182.067 235.723 186.162 231.501C190.281 227.279 195.553 225.168 201.976 225.168C208.719 225.168 213.966 227.083 217.742 230.961C221.518 234.815 223.381 240.24 223.381 247.187V249.003ZM213.451 242.547C213.059 239.553 211.931 237.245 210.019 235.674C208.106 234.103 205.483 233.318 202.148 233.318C199.01 233.318 196.46 234.079 194.572 235.625C192.659 237.171 191.311 239.479 190.551 242.572H213.451V242.547Z"
        fill="black"
      />
      <path
        d="M234.683 268.517V235.035H227.352V226.64H234.683V215.422C234.683 209.85 235.835 205.751 238.14 203.1C240.444 200.449 244.049 199.123 248.879 199.123C249.909 199.123 250.963 199.197 252.066 199.344C253.17 199.491 254.273 199.712 255.376 200.056V208.721C254.616 208.279 253.832 207.935 253.047 207.714C252.262 207.494 251.478 207.371 250.693 207.371C248.413 207.371 246.746 208.083 245.765 209.506C244.76 210.906 244.269 213.311 244.269 216.674V226.665H254.322V235.06H244.269V268.542H234.683V268.517Z"
        fill="black"
      />
      <path
        d="M270.319 226.643V250.232C270.319 254.307 271.153 257.204 272.844 258.996C274.512 260.763 277.233 261.647 281.009 261.647C284.785 261.647 287.506 260.763 289.174 258.996C290.865 257.228 291.699 254.307 291.699 250.232V226.643H301.286V247.36C301.286 255.363 299.692 261.18 296.48 264.813C293.268 268.446 288.168 270.238 281.132 270.238C273.972 270.238 268.799 268.446 265.587 264.838C262.375 261.229 260.781 255.412 260.781 247.336V226.618H270.319V226.643Z"
        fill="black"
      />
      <path
        d="M312.047 268.519V200.033H321.609V268.519H312.047Z"
        fill="black"
      />
      <path
        d="M335.289 268.519V226.642H344.287V233.49C346.2 230.594 348.456 228.507 351.054 227.182C353.653 225.856 356.792 225.193 360.494 225.193C363.608 225.193 366.256 225.684 368.463 226.666C370.645 227.648 372.361 229.121 373.612 231.06C374.347 232.214 374.887 233.539 375.205 235.086C375.524 236.608 375.696 239.308 375.696 243.113V268.519H366.035V246.991C366.035 241.493 365.373 237.811 364.025 236.019C362.676 234.227 360.323 233.294 356.89 233.294C354.659 233.294 352.648 233.711 350.907 234.521C349.142 235.356 347.769 236.51 346.788 238.007C346.077 239.013 345.587 240.29 345.317 241.885C345.048 243.456 344.9 245.96 344.9 249.421V268.519H335.289Z"
        fill="black"
      />
      <path
        d="M428.732 249.003H395.264C395.509 252.906 396.661 255.974 398.77 258.184C400.854 260.368 403.649 261.473 407.106 261.473C410.196 261.473 412.77 260.835 414.83 259.534C416.889 258.233 418.679 256.146 420.224 253.25L428.315 257.742C425.961 261.964 423.043 265.081 419.562 267.143C416.08 269.205 412.01 270.236 407.352 270.236C400.633 270.236 395.288 268.199 391.341 264.099C387.369 260 385.383 254.551 385.383 247.702C385.383 241.124 387.442 235.723 391.537 231.501C395.632 227.279 400.903 225.168 407.352 225.168C414.094 225.168 419.341 227.083 423.117 230.961C426.893 234.815 428.756 240.24 428.756 247.187L428.732 249.003ZM418.802 242.547C418.409 239.553 417.282 237.245 415.369 235.674C413.457 234.103 410.833 233.318 407.523 233.318C404.36 233.318 401.835 234.079 399.922 235.625C398.01 237.171 396.661 239.479 395.901 242.572H418.802V242.547Z"
        fill="black"
      />
      <path
        d="M433.883 260.932L441.827 256.612C442.415 258.698 443.494 260.294 445.088 261.374C446.657 262.479 448.717 263.019 451.217 263.019C453.375 263.019 455.067 262.528 456.268 261.546C457.494 260.564 458.107 259.214 458.107 257.496C458.107 255.237 455.925 253.274 451.585 251.653C450.114 251.113 448.986 250.671 448.202 250.328C443.739 248.462 440.601 246.597 438.811 244.682C437.021 242.767 436.138 240.435 436.138 237.711C436.138 233.979 437.61 230.936 440.527 228.604C443.47 226.272 447.319 225.118 452.149 225.118C455.386 225.118 458.156 225.707 460.461 226.91C462.766 228.113 464.458 229.807 465.512 232.016L458.72 236.704C458.23 235.379 457.347 234.323 456.097 233.538C454.846 232.752 453.4 232.359 451.757 232.359C449.942 232.359 448.471 232.801 447.368 233.66C446.265 234.52 445.725 235.698 445.725 237.122C445.725 239.306 448.202 241.295 453.13 243.086C454.699 243.651 455.901 244.093 456.759 244.461C460.608 245.983 463.379 247.726 465.021 249.641C466.689 251.58 467.522 253.985 467.522 256.857C467.522 261.03 466.002 264.32 462.986 266.676C459.971 269.057 455.778 270.235 450.457 270.235C446.363 270.235 442.979 269.475 440.258 267.928C437.536 266.431 435.427 264.074 433.883 260.932Z"
        fill="black"
      />
      <path
        d="M472.359 260.932L480.303 256.612C480.892 258.698 481.971 260.294 483.564 261.374C485.134 262.479 487.193 263.019 489.694 263.019C491.852 263.019 493.543 262.528 494.745 261.546C495.971 260.564 496.584 259.214 496.584 257.496C496.584 255.237 494.402 253.274 490.062 251.653C488.591 251.113 487.463 250.671 486.678 250.328C482.216 248.462 479.077 246.597 477.288 244.682C475.498 242.767 474.615 240.435 474.615 237.711C474.615 233.979 476.086 230.936 479.004 228.604C481.946 226.272 485.796 225.118 490.626 225.118C493.862 225.118 496.633 225.707 498.937 226.91C501.242 228.113 502.934 229.807 503.988 232.016L497.197 236.704C496.706 235.379 495.824 234.323 494.573 233.538C493.323 232.752 491.876 232.359 490.233 232.359C488.419 232.359 486.948 232.801 485.845 233.66C484.741 234.52 484.202 235.698 484.202 237.122C484.202 239.306 486.678 241.295 491.606 243.086C493.176 243.651 494.377 244.093 495.235 244.461C499.085 245.983 501.855 247.726 503.498 249.641C505.165 251.58 505.999 253.985 505.999 256.857C505.999 261.03 504.479 264.32 501.463 266.676C498.447 269.057 494.255 270.235 488.934 270.235C484.839 270.235 481.456 269.475 478.734 267.928C475.988 266.431 473.88 264.074 472.359 260.932Z"
        fill="black"
      />
      <path
        d="M227.833 105.673H211.773C211.773 85.6423 228.569 69.3431 249.189 69.3431H256.814C268.608 69.3431 278.17 60.2607 278.17 49.0918H294.229C294.229 69.1222 277.434 85.4213 256.814 85.4213H249.189C237.395 85.4213 227.833 94.5037 227.833 105.673Z"
        fill="black"
      />
      <path
        d="M253.01 154.793C210.372 154.793 175.703 120.059 175.703 77.3966C175.703 34.7339 210.372 0 253.01 0C295.648 0 330.317 34.7339 330.317 77.3966C330.317 120.059 295.623 154.793 253.01 154.793ZM253.01 14.9736C218.635 14.9736 190.659 42.9573 190.659 77.3722C190.659 111.787 218.635 139.771 253.01 139.771C287.385 139.771 315.361 111.787 315.361 77.3722C315.361 42.9573 287.385 14.9736 253.01 14.9736Z"
        fill="black"
      />
      <path
        d="M118.771 320.803H117.766L113.328 306.934H114.407L118.256 319.207L122.204 306.934H123.185L127.132 319.207L130.982 306.934H132.06L127.623 320.803H126.642L122.694 308.529L118.771 320.803Z"
        fill="black"
      />
      <path
        d="M144.348 319.821V320.803H134.859V306.934H144.152V307.94H135.84V313.07H142.558V314.077H135.84V319.821H144.348Z"
        fill="black"
      />
      <path
        d="M147.297 320.803V306.934H148.302V319.796H155.707V320.778L147.297 320.803Z"
        fill="black"
      />
      <path
        d="M169.305 306.957V320.826H168.52L159.326 308.847V320.826H158.32V306.957H159.105L168.299 318.936V306.957H169.305Z"
        fill="black"
      />
      <path
        d="M183.121 319.821V320.803H173.633V306.934H182.925V307.94H174.614V313.07H181.332V314.077H174.614V319.821H183.121Z"
        fill="black"
      />
      <path
        d="M192.113 315.476C191.574 315.059 190.936 314.715 190.176 314.494C189.416 314.273 188.656 314.028 187.896 313.758C187.136 313.488 186.499 313.095 185.959 312.531C185.444 311.966 185.175 311.254 185.175 310.395C185.175 309.389 185.567 308.505 186.376 307.793C187.185 307.081 188.239 306.713 189.514 306.713C189.98 306.713 190.446 306.762 190.887 306.885C191.329 307.007 191.697 307.13 191.991 307.302C192.285 307.449 192.555 307.621 192.775 307.793C192.996 307.965 193.168 308.112 193.266 308.235L193.413 308.407L192.726 309.094C192.702 309.045 192.653 308.996 192.604 308.947C192.555 308.898 192.408 308.775 192.211 308.627C192.015 308.48 191.795 308.333 191.549 308.21C191.329 308.087 191.01 307.965 190.642 307.867C190.274 307.768 189.882 307.719 189.465 307.719C188.411 307.719 187.577 307.965 186.989 308.48C186.4 308.996 186.106 309.634 186.106 310.395C186.106 311.082 186.376 311.622 186.891 312.064C187.406 312.481 188.068 312.8 188.828 313.046C189.588 313.267 190.348 313.512 191.108 313.782C191.868 314.028 192.506 314.445 193.045 315.01C193.56 315.574 193.83 316.286 193.83 317.145C193.83 318.275 193.413 319.183 192.604 319.919C191.795 320.656 190.691 320.999 189.294 320.999C188.73 320.999 188.19 320.926 187.651 320.778C187.112 320.631 186.695 320.459 186.351 320.263C186.008 320.066 185.714 319.87 185.469 319.649C185.224 319.428 185.028 319.256 184.929 319.134L184.758 318.913L185.444 318.225C185.469 318.275 185.518 318.348 185.591 318.422C185.665 318.495 185.812 318.643 186.033 318.839C186.253 319.035 186.523 319.232 186.793 319.379C187.062 319.526 187.43 319.674 187.872 319.796C188.313 319.919 188.803 319.993 189.294 319.993C190.446 319.993 191.329 319.723 191.942 319.207C192.555 318.692 192.873 317.98 192.873 317.121C192.898 316.458 192.628 315.918 192.113 315.476Z"
        fill="black"
      />
      <path
        d="M203.145 315.476C202.605 315.059 201.968 314.715 201.208 314.494C200.447 314.273 199.687 314.028 198.927 313.758C198.167 313.488 197.53 313.095 196.99 312.531C196.476 311.966 196.206 311.254 196.206 310.395C196.206 309.389 196.598 308.505 197.407 307.793C198.216 307.081 199.271 306.713 200.546 306.713C201.012 306.713 201.477 306.762 201.919 306.885C202.36 307.007 202.728 307.13 203.022 307.302C203.316 307.449 203.586 307.621 203.807 307.793C204.027 307.965 204.199 308.112 204.297 308.235L204.444 308.407L203.758 309.094C203.733 309.045 203.684 308.996 203.635 308.947C203.586 308.898 203.439 308.775 203.243 308.627C203.047 308.48 202.826 308.333 202.581 308.21C202.36 308.087 202.041 307.965 201.673 307.867C201.306 307.768 200.913 307.719 200.497 307.719C199.442 307.719 198.609 307.965 198.02 308.48C197.432 308.996 197.138 309.634 197.138 310.395C197.138 311.082 197.407 311.622 197.922 312.064C198.437 312.481 199.099 312.8 199.859 313.046C200.619 313.267 201.379 313.512 202.139 313.782C202.899 314.028 203.537 314.445 204.076 315.01C204.591 315.574 204.861 316.286 204.861 317.145C204.861 318.275 204.444 319.183 203.635 319.919C202.826 320.656 201.723 320.999 200.325 320.999C199.761 320.999 199.222 320.926 198.682 320.778C198.143 320.631 197.726 320.459 197.383 320.263C197.039 320.066 196.745 319.87 196.5 319.649C196.255 319.428 196.059 319.256 195.961 319.134L195.789 318.913L196.476 318.225C196.5 318.275 196.549 318.348 196.623 318.422C196.696 318.495 196.843 318.643 197.064 318.839C197.285 319.035 197.554 319.232 197.824 319.379C198.094 319.526 198.461 319.674 198.903 319.796C199.344 319.919 199.835 319.993 200.325 319.993C201.477 319.993 202.36 319.723 202.973 319.207C203.586 318.692 203.905 317.98 203.905 317.121C203.929 316.458 203.684 315.918 203.145 315.476Z"
        fill="black"
      />
      <path
        d="M220.985 306.957V307.964H212.771V313.315H219.391V314.297H212.771V320.826H211.766V306.957H220.985Z"
        fill="black"
      />
      <path
        d="M234.45 318.912C233.077 320.286 231.435 320.998 229.473 320.998C227.536 320.998 225.893 320.311 224.52 318.912C223.147 317.537 222.461 315.843 222.461 313.855C222.461 311.867 223.147 310.197 224.52 308.823C225.893 307.448 227.536 306.736 229.473 306.736C231.435 306.736 233.077 307.424 234.45 308.823C235.823 310.197 236.486 311.891 236.486 313.855C236.486 315.819 235.823 317.537 234.45 318.912ZM233.764 309.51C232.587 308.332 231.165 307.743 229.473 307.743C227.781 307.743 226.359 308.332 225.182 309.51C224.006 310.688 223.442 312.137 223.442 313.879C223.442 315.598 224.03 317.071 225.182 318.249C226.359 319.427 227.781 320.016 229.473 320.016C231.165 320.016 232.587 319.427 233.764 318.249C234.941 317.071 235.505 315.622 235.505 313.879C235.505 312.161 234.941 310.688 233.764 309.51Z"
        fill="black"
      />
      <path
        d="M245.075 306.957C246.301 306.957 247.306 307.35 248.115 308.135C248.925 308.921 249.317 309.878 249.317 311.032C249.317 311.646 249.219 312.186 248.998 312.677C248.802 313.167 248.532 313.536 248.238 313.806C247.944 314.076 247.625 314.297 247.331 314.468C247.037 314.64 246.767 314.763 246.571 314.812L246.252 314.91L249.611 320.851H248.532L245.271 315.107H240.833V320.851H239.828V306.982L245.075 306.957ZM240.833 307.939V314.076H245.075C246.056 314.076 246.841 313.781 247.429 313.216C248.017 312.652 248.336 311.916 248.336 311.007C248.336 310.099 248.042 309.363 247.429 308.798C246.841 308.233 246.031 307.939 245.075 307.939H240.833Z"
        fill="black"
      />
      <path
        d="M260.437 314.862L264.875 306.934H266.052L260.903 315.942V320.803H259.922V315.942L254.773 306.934H255.95L260.437 314.862Z"
        fill="black"
      />
      <path
        d="M278.919 318.912C277.546 320.286 275.904 320.998 273.942 320.998C272.005 320.998 270.362 320.311 268.989 318.912C267.616 317.537 266.93 315.843 266.93 313.855C266.93 311.867 267.616 310.197 268.989 308.823C270.362 307.448 272.005 306.736 273.942 306.736C275.904 306.736 277.546 307.424 278.919 308.823C280.292 310.197 280.954 311.891 280.954 313.855C280.954 315.819 280.292 317.537 278.919 318.912ZM278.233 309.51C277.056 308.332 275.634 307.743 273.942 307.743C272.25 307.743 270.828 308.332 269.651 309.51C268.474 310.688 267.91 312.137 267.91 313.879C267.91 315.598 268.499 317.071 269.651 318.249C270.828 319.427 272.25 320.016 273.942 320.016C275.634 320.016 277.056 319.427 278.233 318.249C279.41 317.071 279.974 315.622 279.974 313.879C279.998 312.161 279.41 310.688 278.233 309.51Z"
        fill="black"
      />
      <path
        d="M292.713 318.692C293.571 317.808 294.013 316.728 294.013 315.451V306.934H294.993V315.451C294.993 316.973 294.454 318.299 293.4 319.379C292.321 320.459 291.046 320.999 289.575 320.999C288.104 320.999 286.804 320.459 285.75 319.379C284.671 318.299 284.156 316.998 284.156 315.451V306.934H285.137V315.451C285.137 316.728 285.578 317.808 286.436 318.692C287.295 319.575 288.349 320.017 289.599 320.017C290.85 320.017 291.855 319.575 292.713 318.692Z"
        fill="black"
      />
      <path
        d="M304.364 306.957C305.59 306.957 306.595 307.35 307.404 308.135C308.213 308.921 308.606 309.878 308.606 311.032C308.606 311.646 308.508 312.186 308.287 312.677C308.091 313.167 307.821 313.536 307.527 313.806C307.233 314.076 306.914 314.297 306.62 314.468C306.326 314.64 306.056 314.763 305.86 314.812L305.541 314.91L308.9 320.851H307.821L304.56 315.107H300.122V320.851H299.117V306.982L304.364 306.957ZM300.122 307.939V314.076H304.364C305.345 314.076 306.129 313.781 306.718 313.216C307.306 312.652 307.625 311.916 307.625 311.007C307.625 310.099 307.331 309.363 306.718 308.798C306.129 308.233 305.32 307.939 304.364 307.939H300.122Z"
        fill="black"
      />
      <path
        d="M319.646 320.803H318.641L314.203 306.934H315.282L319.131 319.207L323.079 306.934H324.06L328.007 319.207L331.857 306.934H332.935L328.498 320.803H327.517L323.569 308.529L319.646 320.803Z"
        fill="black"
      />
      <path
        d="M345.207 319.821V320.803H335.719V306.934H345.011V307.94H336.699V313.07H343.417V314.077H336.699V319.821H345.207Z"
        fill="black"
      />
      <path
        d="M349.255 316.752L347.563 320.803H346.484L352.222 306.934H353.227L358.94 320.803H357.861L356.169 316.752H349.255ZM352.712 308.333L349.647 315.746H355.777L352.712 308.333Z"
        fill="black"
      />
      <path
        d="M361.578 320.803V306.934H362.583V319.796H369.988V320.778L361.578 320.803Z"
        fill="black"
      />
      <path
        d="M379.756 306.957V307.964H375.22V320.826H374.239V307.964H369.703V306.957H379.756Z"
        fill="black"
      />
      <path
        d="M382.305 306.957H383.31V313.29H391.72V306.957H392.701V320.826H391.72V314.297H383.31V320.826H382.305V306.957Z"
        fill="black"
      />
    </SvgIcon>
  )
}
export function WhiteFullVerticalWithTextIcon({ sx }: { sx?: SxProps }) {
  return (
    <SvgIcon viewBox="0 0 506 321" sx={sx}>
      <path
        d="M27.6567 212.513V268.216H17.1628V212.513H0V203.589H44.7459V212.513H27.6567Z"
        fill="white"
      />
      <path
        d="M54.195 233.035C55.2248 230.264 56.5732 228.23 58.265 226.906C59.9567 225.582 62.0408 224.92 64.5662 224.92C65.9147 224.92 67.2387 225.091 68.5627 225.41C69.8622 225.729 71.1616 226.195 72.4121 226.808L68.9795 234.923C68.1458 234.334 67.2633 233.893 66.307 233.624C65.3508 233.354 64.2966 233.207 63.1197 233.207C59.9323 233.207 57.652 234.481 56.279 237.031C54.906 239.581 54.2195 243.847 54.2195 249.854V268.217H44.6328V226.293H54.2195L54.195 233.035Z"
        fill="white"
      />
      <path
        d="M117.255 268.194H107.693V262.775C105.976 265.178 103.99 266.992 101.71 268.169C99.43 269.346 96.8066 269.934 93.8644 269.934C87.9555 269.934 83.297 267.899 79.8889 263.854C76.5054 259.809 74.7891 254.268 74.7891 247.232C74.7891 240.563 76.5299 235.169 80.0115 231.05C83.4931 226.956 88.0045 224.896 93.5947 224.896C97.0027 224.896 99.9204 225.607 102.348 227.029C104.775 228.451 106.54 230.511 107.668 233.183V226.343H117.23L117.255 268.194ZM107.938 247.158C107.938 242.843 106.933 239.46 104.922 237.057C102.912 234.654 100.092 233.428 96.4878 233.428C92.8836 233.428 90.0149 234.679 87.9554 237.155C85.8713 239.631 84.8417 243.088 84.8417 247.501C84.8417 251.939 85.8469 255.371 87.8574 257.774C89.8679 260.177 92.6875 261.402 96.3407 261.402C99.7733 261.402 102.593 260.103 104.75 257.48C106.859 254.856 107.938 251.424 107.938 247.158Z"
        fill="white"
      />
      <path
        d="M160.294 199.813H169.856V268.216H160.294V262.798C158.578 265.2 156.592 267.015 154.312 268.192C152.031 269.368 149.408 269.957 146.466 269.957C140.557 269.957 135.899 267.922 132.49 263.877C129.107 259.831 127.391 254.29 127.391 247.254C127.391 240.585 129.131 235.192 132.613 231.073C136.095 226.978 140.606 224.919 146.196 224.919C149.604 224.919 152.522 225.63 154.949 227.052C157.376 228.474 159.142 230.533 160.27 233.206L160.294 199.813ZM160.564 247.156C160.564 242.841 159.559 239.457 157.548 237.055C155.538 234.652 152.718 233.426 149.114 233.426C145.51 233.426 142.641 234.677 140.581 237.153C138.497 239.629 137.468 243.086 137.468 247.499C137.468 251.937 138.473 255.369 140.483 257.772C142.494 260.175 145.313 261.4 148.967 261.4C152.448 261.4 155.243 260.101 157.401 257.502C159.485 254.903 160.564 251.446 160.564 247.156Z"
        fill="white"
      />
      <path
        d="M223.388 248.702H189.946C190.191 252.601 191.343 255.665 193.427 257.872C195.511 260.054 198.306 261.157 201.763 261.157C204.853 261.157 207.427 260.52 209.487 259.22C211.546 257.921 213.336 255.837 214.856 252.944L222.947 257.431C220.593 261.648 217.676 264.761 214.219 266.821C210.761 268.88 206.667 269.91 201.984 269.91C195.266 269.91 189.946 267.875 185.974 263.781C182.002 259.686 180.016 254.244 180.016 247.403C180.016 240.833 182.075 235.439 186.17 231.222C190.289 227.005 195.56 224.896 201.984 224.896C208.726 224.896 213.973 226.809 217.749 230.682C221.525 234.532 223.388 239.95 223.388 246.888V248.702ZM213.458 242.254C213.066 239.263 211.938 236.959 210.026 235.39C208.114 233.821 205.49 233.036 202.156 233.036C199.017 233.036 196.467 233.796 194.579 235.341C192.667 236.885 191.319 239.19 190.558 242.279H213.458V242.254Z"
        fill="white"
      />
      <path
        d="M234.683 268.192V234.75H227.352V226.365H234.683V215.161C234.683 209.596 235.835 205.501 238.14 202.854C240.444 200.206 244.048 198.882 248.879 198.882C249.908 198.882 250.963 198.955 252.066 199.102C253.169 199.25 254.273 199.47 255.376 199.813V208.468C254.616 208.027 253.831 207.683 253.047 207.463C252.262 207.242 251.477 207.12 250.693 207.12C248.413 207.12 246.746 207.831 245.765 209.253C244.76 210.65 244.269 213.053 244.269 216.412V226.39H254.322V234.775H244.269V268.216H234.683V268.192Z"
        fill="white"
      />
      <path
        d="M270.319 226.366V249.927C270.319 253.997 271.152 256.89 272.844 258.68C274.511 260.445 277.233 261.328 281.009 261.328C284.785 261.328 287.506 260.445 289.173 258.68C290.865 256.915 291.699 253.997 291.699 249.927V226.366H301.285V247.059C301.285 255.051 299.692 260.862 296.48 264.491C293.268 268.119 288.168 269.909 281.131 269.909C273.972 269.909 268.799 268.119 265.587 264.515C262.375 260.911 260.781 255.1 260.781 247.034V226.342H270.319V226.366Z"
        fill="white"
      />
      <path d="M312.062 268.193V199.79H321.625V268.193H312.062Z" fill="white" />
      <path
        d="M335.289 268.193V226.366H344.287V233.207C346.2 230.314 348.455 228.23 351.054 226.906C353.653 225.582 356.792 224.92 360.494 224.92C363.608 224.92 366.256 225.41 368.462 226.391C370.645 227.372 372.361 228.843 373.611 230.78C374.347 231.932 374.886 233.256 375.205 234.8C375.524 236.32 375.695 239.017 375.695 242.818V268.193H366.035V246.691C366.035 241.199 365.373 237.522 364.025 235.732C362.676 233.942 360.322 233.011 356.89 233.011C354.659 233.011 352.648 233.427 350.907 234.237C349.142 235.07 347.769 236.222 346.788 237.718C346.077 238.723 345.587 239.998 345.317 241.592C345.047 243.161 344.9 245.661 344.9 249.118V268.193H335.289Z"
        fill="white"
      />
      <path
        d="M428.731 248.702H395.264C395.509 252.601 396.661 255.665 398.77 257.872C400.854 260.054 403.649 261.157 407.106 261.157C410.195 261.157 412.77 260.52 414.829 259.22C416.889 257.921 418.679 255.837 420.223 252.944L428.314 257.431C425.961 261.648 423.043 264.761 419.561 266.821C416.08 268.88 412.01 269.91 407.351 269.91C400.633 269.91 395.288 267.875 391.341 263.781C387.369 259.686 385.383 254.244 385.383 247.403C385.383 240.833 387.442 235.439 391.537 231.222C395.631 227.005 400.903 224.896 407.351 224.896C414.094 224.896 419.341 226.809 423.116 230.682C426.892 234.532 428.756 239.95 428.756 246.888L428.731 248.702ZM418.801 242.254C418.409 239.263 417.281 236.959 415.369 235.39C413.456 233.821 410.833 233.036 407.523 233.036C404.36 233.036 401.835 233.796 399.922 235.341C398.01 236.885 396.661 239.19 395.901 242.279H418.801V242.254Z"
        fill="white"
      />
      <path
        d="M433.883 260.616L441.827 256.301C442.415 258.385 443.494 259.979 445.088 261.057C446.657 262.161 448.716 262.7 451.217 262.7C453.375 262.7 455.067 262.21 456.268 261.229C457.494 260.248 458.107 258.9 458.107 257.184C458.107 254.928 455.925 252.967 451.585 251.349C450.114 250.809 448.986 250.368 448.202 250.025C443.739 248.161 440.601 246.298 438.811 244.386C437.021 242.473 436.138 240.144 436.138 237.423C436.138 233.696 437.61 230.656 440.527 228.327C443.47 225.998 447.319 224.846 452.149 224.846C455.385 224.846 458.156 225.434 460.461 226.635C462.765 227.837 464.457 229.529 465.511 231.735L458.72 236.418C458.229 235.094 457.347 234.04 456.096 233.255C454.846 232.471 453.399 232.078 451.757 232.078C449.942 232.078 448.471 232.519 447.368 233.378C446.265 234.236 445.725 235.413 445.725 236.835C445.725 239.017 448.201 241.003 453.13 242.792C454.699 243.356 455.9 243.797 456.758 244.165C460.608 245.685 463.378 247.426 465.021 249.338C466.688 251.275 467.522 253.678 467.522 256.546C467.522 260.714 466.002 264 462.986 266.353C459.97 268.731 455.778 269.908 450.457 269.908C446.363 269.908 442.979 269.148 440.258 267.604C437.536 266.108 435.427 263.754 433.883 260.616Z"
        fill="white"
      />
      <path
        d="M472.359 260.616L480.303 256.301C480.892 258.385 481.971 259.979 483.564 261.057C485.133 262.161 487.193 262.7 489.694 262.7C491.851 262.7 493.543 262.21 494.745 261.229C495.97 260.248 496.583 258.9 496.583 257.184C496.583 254.928 494.401 252.967 490.062 251.349C488.59 250.809 487.463 250.368 486.678 250.025C482.216 248.161 479.077 246.298 477.288 244.386C475.498 242.473 474.615 240.144 474.615 237.423C474.615 233.696 476.086 230.656 479.004 228.327C481.946 225.998 485.795 224.846 490.626 224.846C493.862 224.846 496.632 225.434 498.937 226.635C501.242 227.837 502.934 229.529 503.988 231.735L497.196 236.418C496.706 235.094 495.823 234.04 494.573 233.255C493.323 232.471 491.876 232.078 490.233 232.078C488.419 232.078 486.948 232.519 485.844 233.378C484.741 234.236 484.202 235.413 484.202 236.835C484.202 239.017 486.678 241.003 491.606 242.792C493.175 243.356 494.377 243.797 495.235 244.165C499.084 245.685 501.855 247.426 503.498 249.338C505.165 251.275 505.998 253.678 505.998 256.546C505.998 260.714 504.478 264 501.463 266.353C498.447 268.731 494.254 269.908 488.934 269.908C484.839 269.908 481.456 269.148 478.734 267.604C475.988 266.108 473.88 263.754 472.359 260.616Z"
        fill="white"
      />
      <path
        d="M227.817 105.546H211.758C211.758 85.5399 228.553 69.2608 249.173 69.2608H256.798C268.591 69.2608 278.153 60.1894 278.153 49.0342H294.213C294.213 69.0401 277.418 85.3193 256.798 85.3193H249.173C237.379 85.3193 227.817 94.3906 227.817 105.546Z"
        fill="white"
      />
      <path
        d="M253.001 154.604C210.364 154.604 175.695 119.913 175.695 77.3024C175.695 34.6917 210.364 0 253.001 0C295.639 0 330.308 34.6917 330.308 77.3024C330.308 119.913 295.614 154.604 253.001 154.604ZM253.001 14.9554C218.627 14.9554 190.651 42.9049 190.651 77.2779C190.651 111.651 218.627 139.6 253.001 139.6C287.376 139.6 315.351 111.651 315.351 77.2779C315.351 42.9049 287.376 14.9554 253.001 14.9554Z"
        fill="white"
      />
      <path
        d="M118.763 320.804H117.758L113.32 306.952H114.399L118.249 319.211L122.196 306.952H123.177L127.124 319.211L130.974 306.952H132.052L127.615 320.804H126.634L122.686 308.546L118.763 320.804Z"
        fill="white"
      />
      <path
        d="M144.34 319.824V320.804H134.852V306.952H144.144V307.957H135.832V313.081H142.55V314.086H135.832V319.824H144.34Z"
        fill="white"
      />
      <path
        d="M147.281 320.804V306.952H148.287V319.799H155.691V320.78H147.281V320.804Z"
        fill="white"
      />
      <path
        d="M169.297 306.976V320.828H168.512L159.318 308.863V320.828H158.312V306.976H159.097L168.291 318.94V306.976H169.297Z"
        fill="white"
      />
      <path
        d="M183.114 319.824V320.804H173.625V306.952H182.918V307.957H174.606V313.081H181.324V314.086H174.606V319.824H183.114Z"
        fill="white"
      />
      <path
        d="M192.098 315.484C191.558 315.067 190.921 314.724 190.161 314.503C189.401 314.283 188.641 314.038 187.88 313.768C187.12 313.498 186.483 313.106 185.944 312.542C185.429 311.978 185.159 311.267 185.159 310.409C185.159 309.404 185.551 308.521 186.36 307.81C187.169 307.099 188.224 306.731 189.499 306.731C189.965 306.731 190.43 306.781 190.872 306.903C191.313 307.026 191.681 307.148 191.975 307.32C192.269 307.467 192.539 307.639 192.76 307.81C192.98 307.982 193.152 308.129 193.25 308.251L193.397 308.423L192.711 309.11C192.686 309.06 192.637 309.012 192.588 308.963C192.539 308.914 192.392 308.791 192.196 308.644C192 308.497 191.779 308.35 191.534 308.227C191.313 308.104 190.994 307.982 190.626 307.884C190.259 307.786 189.866 307.737 189.45 307.737C188.395 307.737 187.562 307.982 186.973 308.497C186.385 309.011 186.091 309.649 186.091 310.409C186.091 311.095 186.36 311.635 186.875 312.076C187.39 312.493 188.052 312.812 188.812 313.057C189.572 313.278 190.332 313.523 191.092 313.792C191.852 314.038 192.49 314.454 193.029 315.018C193.544 315.582 193.814 316.293 193.814 317.151C193.814 318.279 193.397 319.186 192.588 319.922C191.779 320.657 190.676 321.001 189.278 321.001C188.714 321.001 188.175 320.927 187.635 320.78C187.096 320.633 186.679 320.461 186.336 320.265C185.993 320.069 185.698 319.873 185.453 319.652C185.208 319.431 185.012 319.26 184.914 319.137L184.742 318.916L185.429 318.23C185.453 318.279 185.502 318.353 185.576 318.426C185.649 318.5 185.796 318.647 186.017 318.843C186.238 319.039 186.507 319.235 186.777 319.382C187.047 319.529 187.415 319.677 187.856 319.799C188.297 319.922 188.788 319.995 189.278 319.995C190.43 319.995 191.313 319.726 191.926 319.211C192.539 318.696 192.858 317.985 192.858 317.127C192.882 316.465 192.613 315.926 192.098 315.484Z"
        fill="white"
      />
      <path
        d="M203.137 315.484C202.597 315.067 201.96 314.724 201.2 314.503C200.44 314.283 199.68 314.038 198.92 313.768C198.159 313.498 197.522 313.106 196.983 312.542C196.468 311.978 196.198 311.267 196.198 310.409C196.198 309.404 196.59 308.521 197.399 307.81C198.208 307.099 199.263 306.731 200.538 306.731C201.004 306.731 201.469 306.781 201.911 306.903C202.352 307.026 202.72 307.148 203.014 307.32C203.308 307.467 203.578 307.639 203.799 307.81C204.019 307.982 204.191 308.129 204.289 308.251L204.436 308.423L203.75 309.11C203.725 309.06 203.676 309.012 203.627 308.963C203.578 308.914 203.431 308.791 203.235 308.644C203.039 308.497 202.818 308.35 202.573 308.227C202.352 308.104 202.033 307.982 201.666 307.884C201.298 307.786 200.906 307.737 200.489 307.737C199.434 307.737 198.601 307.982 198.012 308.497C197.424 309.011 197.13 309.649 197.13 310.409C197.13 311.095 197.399 311.635 197.914 312.076C198.429 312.493 199.091 312.812 199.851 313.057C200.611 313.278 201.371 313.523 202.131 313.792C202.891 314.038 203.529 314.454 204.068 315.018C204.583 315.582 204.853 316.293 204.853 317.151C204.853 318.279 204.436 319.186 203.627 319.922C202.818 320.657 201.715 321.001 200.317 321.001C199.753 321.001 199.214 320.927 198.674 320.78C198.135 320.633 197.718 320.461 197.375 320.265C197.032 320.069 196.737 319.873 196.492 319.652C196.247 319.431 196.051 319.26 195.953 319.137L195.781 318.916L196.468 318.23C196.492 318.279 196.541 318.353 196.615 318.426C196.688 318.5 196.835 318.647 197.056 318.843C197.277 319.039 197.546 319.235 197.816 319.382C198.086 319.529 198.454 319.677 198.895 319.799C199.336 319.922 199.827 319.995 200.317 319.995C201.469 319.995 202.352 319.726 202.965 319.211C203.578 318.696 203.897 317.985 203.897 317.127C203.921 316.465 203.676 315.926 203.137 315.484Z"
        fill="white"
      />
      <path
        d="M220.977 306.976V307.981H212.763V313.326H219.383V314.306H212.763V320.828H211.758V306.976H220.977Z"
        fill="white"
      />
      <path
        d="M234.435 318.916C233.062 320.289 231.419 321 229.458 321C227.521 321 225.878 320.314 224.505 318.916C223.132 317.543 222.445 315.852 222.445 313.866C222.445 311.88 223.132 310.213 224.505 308.84C225.878 307.467 227.521 306.756 229.458 306.756C231.419 306.756 233.062 307.442 234.435 308.84C235.808 310.213 236.47 311.905 236.47 313.866C236.47 315.827 235.808 317.543 234.435 318.916ZM233.748 309.526C232.571 308.349 231.149 307.761 229.458 307.761C227.766 307.761 226.344 308.349 225.167 309.526C223.99 310.703 223.426 312.15 223.426 313.89C223.426 315.607 224.014 317.078 225.167 318.255C226.344 319.431 227.766 320.02 229.458 320.02C231.149 320.02 232.571 319.431 233.748 318.255C234.925 317.078 235.489 315.631 235.489 313.89C235.489 312.174 234.925 310.703 233.748 309.526Z"
        fill="white"
      />
      <path
        d="M245.067 306.976C246.293 306.976 247.298 307.368 248.108 308.153C248.917 308.937 249.309 309.893 249.309 311.046C249.309 311.658 249.211 312.198 248.99 312.688C248.794 313.179 248.524 313.546 248.23 313.816C247.936 314.086 247.617 314.306 247.323 314.478C247.029 314.65 246.759 314.772 246.563 314.821L246.244 314.919L249.603 320.852H248.524L245.263 315.115H240.826V320.852H239.82V307H245.067V306.976ZM240.826 307.956V314.086H245.067C246.048 314.086 246.833 313.791 247.421 313.228C248.01 312.664 248.328 311.928 248.328 311.021C248.328 310.114 248.034 309.378 247.421 308.814C246.833 308.25 246.023 307.956 245.067 307.956H240.826Z"
        fill="white"
      />
      <path
        d="M260.422 314.871L264.859 306.952H266.036L260.887 315.95V320.804H259.907V315.95L254.758 306.952H255.935L260.422 314.871Z"
        fill="white"
      />
      <path
        d="M278.911 318.916C277.538 320.289 275.896 321 273.934 321C271.997 321 270.355 320.314 268.981 318.916C267.608 317.543 266.922 315.852 266.922 313.866C266.922 311.88 267.608 310.213 268.981 308.84C270.355 307.467 271.997 306.756 273.934 306.756C275.896 306.756 277.538 307.442 278.911 308.84C280.284 310.213 280.946 311.905 280.946 313.866C280.946 315.827 280.284 317.543 278.911 318.916ZM278.225 309.526C277.048 308.349 275.626 307.761 273.934 307.761C272.242 307.761 270.82 308.349 269.644 309.526C268.467 310.703 267.903 312.15 267.903 313.89C267.903 315.607 268.491 317.078 269.644 318.255C270.82 319.431 272.242 320.02 273.934 320.02C275.626 320.02 277.048 319.431 278.225 318.255C279.402 317.078 279.966 315.631 279.966 313.89C279.99 312.174 279.402 310.703 278.225 309.526Z"
        fill="white"
      />
      <path
        d="M292.697 318.696C293.556 317.813 293.997 316.734 293.997 315.46V306.952H294.978V315.46C294.978 316.98 294.438 318.303 293.384 319.382C292.305 320.461 291.03 321 289.559 321C288.088 321 286.789 320.461 285.734 319.382C284.656 318.303 284.141 317.004 284.141 315.46V306.952H285.121V315.46C285.121 316.734 285.563 317.813 286.421 318.696C287.279 319.578 288.333 320.02 289.584 320.02C290.834 320.02 291.839 319.578 292.697 318.696Z"
        fill="white"
      />
      <path
        d="M304.348 306.976C305.574 306.976 306.58 307.368 307.389 308.153C308.198 308.937 308.59 309.893 308.59 311.046C308.59 311.658 308.492 312.198 308.271 312.688C308.075 313.179 307.806 313.546 307.511 313.816C307.217 314.086 306.898 314.306 306.604 314.478C306.31 314.65 306.04 314.772 305.844 314.821L305.525 314.919L308.884 320.852H307.806L304.545 315.115H300.107V320.852H299.102V307H304.348V306.976ZM300.107 307.956V314.086H304.348C305.329 314.086 306.114 313.791 306.702 313.228C307.291 312.664 307.609 311.928 307.609 311.021C307.609 310.114 307.315 309.378 306.702 308.814C306.114 308.25 305.305 307.956 304.348 307.956H300.107Z"
        fill="white"
      />
      <path
        d="M319.646 320.804H318.641L314.203 306.952H315.282L319.131 319.211L323.079 306.952H324.06L328.007 319.211L331.856 306.952H332.935L328.497 320.804H327.517L323.569 308.546L319.646 320.804Z"
        fill="white"
      />
      <path
        d="M345.192 319.824V320.804H335.703V306.952H344.996V307.957H336.684V313.081H343.402V314.086H336.684V319.824H345.192Z"
        fill="white"
      />
      <path
        d="M349.247 316.759L347.555 320.804H346.477L352.214 306.952H353.219L358.932 320.804H357.853L356.161 316.759H349.247ZM352.704 308.349L349.64 315.754H355.769L352.704 308.349Z"
        fill="white"
      />
      <path
        d="M361.57 320.804V306.952H362.575V319.799H369.98V320.78H361.57V320.804Z"
        fill="white"
      />
      <path
        d="M379.748 306.976V307.981H375.212V320.828H374.231V307.981H369.695V306.976H379.748Z"
        fill="white"
      />
      <path
        d="M382.289 306.976H383.294V313.301H391.704V306.976H392.685V320.828H391.704V314.306H383.294V320.828H382.289V306.976Z"
        fill="white"
      />
    </SvgIcon>
  )
}
