import * as React from 'react'
import { useState } from 'react'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import Textarea from '@mui/joy/Textarea'
import { IconButton, Stack } from '@mui/joy'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import { TextMessageRequest } from '../../../../../api/requestTypes'
import { WEBSOCKET_EMIT_EVENTS } from '../../../../../constants/api'
import { useSocket } from '../../../../components/context/SocketContext'
import { parseDate, parseFileSize } from '../../../../../util/utils'
import StopIcon from '@mui/icons-material/Stop'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice'
import FileUpload from './FileUpload'
import { useUserSettings } from '../../../../components/context/UserSettingsContext'
import { UserMessage } from '../../../../types'
import { useChatHistory } from '../../../../components/context/ChatHistoryContext'
import {
  startVoiceRecording,
  stopVoiceRecording,
} from '../../audio-input-utils'
import { useAudioInputControls } from '../../../../components/context/AudioInputControlsContext'
import { useAudioOutputControls } from '../../../../components/context/AudioOutputControlsContext'
import { stopVoiceResponse } from '../../audio-output-utils'

const ChatInput: React.FC = () => {
  const socket = useSocket()
  const { userSettings } = useUserSettings()
  const inputControls = useAudioInputControls()
  const { controls, setSettings } = useAudioOutputControls()
  const { setChatHistory } = useChatHistory()
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null)
  const [isVoiceEnabled, setIsVoiceEnabled] = useState<boolean>(false)
  const textAreaRef = React.useRef<HTMLDivElement>(null)

  const handleVoiceChange = () => {
    if (!isVoiceEnabled) {
      startVoiceInput()
      stopVoiceResponse(controls, setSettings)
    } else {
      stopVoiceInput()
    }
  }

  const startVoiceInput = () => {
    startVoiceRecording(socket, inputControls, setTextAreaValue)
    setIsVoiceEnabled(true)
  }

  const stopVoiceInput = () => {
    stopVoiceRecording(socket, inputControls)
    setIsVoiceEnabled(false)
  }

  const sendMessage = () => {
    if (socket && (textAreaValue.trim() !== '' || attachmentFile !== null)) {
      stopVoiceInput()
      stopVoiceResponse(controls, setSettings)
      const request: TextMessageRequest = {
        message: textAreaValue,
        ...(attachmentFile && {
          attachment: {
            name: attachmentFile.name,
            type: attachmentFile.type,
            content: attachmentFile,
          },
        }),
        timestamp: new Date(),
        tts_enabled: userSettings.voice_response_enabled,
      }
      socket.emit(WEBSOCKET_EMIT_EVENTS.TEXT_MESSAGE, request)

      const sent = new UserMessage(
        textAreaValue,
        parseDate(request.timestamp),
        attachmentFile
          ? {
              name: attachmentFile.name,
              size: parseFileSize(attachmentFile.size),
            }
          : undefined
      )
      setChatHistory((chatHistory) => ({
        messages: [...chatHistory.messages, sent],
      }))
      setTextAreaValue('')
      setAttachmentFile(null)
    }
  }

  return (
    <Box sx={{ px: 2, pb: 3 }}>
      <FormControl>
        <Textarea
          placeholder="Type something here…"
          aria-label="Message"
          ref={textAreaRef}
          onChange={(e) => {
            setTextAreaValue(e.target.value)
          }}
          value={textAreaValue}
          minRows={3}
          maxRows={10}
          endDecorator={
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              flexGrow={1}
              sx={{
                py: 1,
                pr: 1,
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <FileUpload
                file={attachmentFile}
                onFileChange={setAttachmentFile}
              />
              <IconButton
                size="md"
                variant="plain"
                color="primary"
                onClick={() => handleVoiceChange()}
              >
                {isVoiceEnabled ? <StopIcon /> : <KeyboardVoiceIcon />}
              </IconButton>
              <Button
                size="sm"
                color="primary"
                sx={{
                  alignSelf: 'center',
                  borderRadius: 'sm',
                  mx: '5px',
                }}
                endDecorator={<SendRoundedIcon />}
                onClick={sendMessage}
              >
                Send
              </Button>
            </Stack>
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              sendMessage()
            }
          }}
          sx={{
            '& textarea:first-of-type': {
              minHeight: 72,
            },
          }}
        />
      </FormControl>
    </Box>
  )
}

export default ChatInput
