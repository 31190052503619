import * as React from 'react'
import Button from '@mui/joy/Button'
import Divider from '@mui/joy/Divider'
import DialogTitle from '@mui/joy/DialogTitle'
import DialogContent from '@mui/joy/DialogContent'
import DialogActions from '@mui/joy/DialogActions'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { useSettings } from './context/Settings'

export interface ModalAlertType {
  isModalOpen: boolean
  onClickReturnAndSave: () => void
  onClickLeaveWithoutChangesButton: () => void
  title: string
  subTitle: string
  leaveWithoutChangesButton: string
  continueEditing: string
}

export const ModalAlert: React.FC<ModalAlertType> = ({
  isModalOpen,
  onClickReturnAndSave,
  onClickLeaveWithoutChangesButton,
  title,
  subTitle,
  leaveWithoutChangesButton,
  continueEditing,
}) => {
  const { setSettings } = useSettings()

  return (
    <React.Fragment>
      <Modal open={isModalOpen} onClose={() => onClickReturnAndSave()}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {title}
          </DialogTitle>
          <Divider />
          <DialogContent>{subTitle}</DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => {
                setSettings((prev) => ({
                  ...prev,
                  modal: {
                    ...prev.modal,
                    hasUnsavedChanges: false,
                    isModalOpen: false,
                  },
                }))
                onClickLeaveWithoutChangesButton()
              }}
            >
              {leaveWithoutChangesButton}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => onClickReturnAndSave()}
            >
              {continueEditing}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  )
}
