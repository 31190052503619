import * as React from 'react'
import { useColorScheme } from '@mui/joy/styles'
import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'

const LoginHeader: React.FC = () => {
  const { mode, setMode } = useColorScheme()
  const [mounted, setMounted] = React.useState(false)

  React.useEffect(() => setMounted(true), [])

  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <IconButton
        aria-label="toggle light/dark mode"
        sx={{
          width: { xs: '20px', md: '30px', lg: '40px' },
          height: { xs: '20px', md: '30px', lg: '40px' },
        }}
        variant="outlined"
        disabled={!mounted}
        onClick={(event) => {
          setMode(mode === 'light' ? 'dark' : 'light')
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Box>
  )
}

export default LoginHeader
