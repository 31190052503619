export const parseTimestampString = (timestamp: string) => {
    const date = new Date(timestamp);
    return parseDate(date);
}

export const parseDate = (date: Date) => {
    return `${date.toLocaleString('en-US', {
        dateStyle: 'short',
        timeStyle: "short"
    })}`;
}

export const isBeforeOrEquals = (date1: Date, date2: Date) => {
    return date1 <= date2;
}

export const parseFileSize = (fileSize: number) => {
    const fileSizeKb = fileSize / 1024
    if (fileSizeKb < 500) {
        return `${(fileSizeKb).toFixed(2)} kB`;
    } else {
        return `${(fileSizeKb / 1024).toFixed(2)} MB`;
    }
}

export const truncateFileName = (fileName: string, maxLength: number) => {
    if (fileName.length <= maxLength || maxLength === 0) return fileName;
    const extension = fileName.split('.').pop() ?? '';
    const name = fileName.substring(0, fileName.lastIndexOf('.'));
    return `${name.substring(0, maxLength - 3 - extension.length)}...${extension}`;
};

export const formatCamelCaseToSnakeCase = (input: string) => {
    if (!input) return input;
    return input
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
        .toLowerCase();
}

export const formatObjectKeysFromCamelToSnakeCase = <T extends object>(obj: T): any => {
    if (Array.isArray(obj)) {
        return obj.map(item => formatObjectKeysFromCamelToSnakeCase(item));
    }

    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
        const snakeCaseKey = formatCamelCaseToSnakeCase(key);
        acc[snakeCaseKey] = typeof value === 'object'
            ? formatObjectKeysFromCamelToSnakeCase(value)
            : value;
        return acc;
    }, {} as { [key: string]: any });
};

export const formatObjectKeysFromSnakeToCamelCase = <T extends object>(obj: T): any => {
    if (typeof obj === 'object' && obj !== null) {
        const newObj: { [key: string]: any } = {};

        Object.keys(obj).forEach((key) => {
            const value = obj[key as keyof typeof obj];
            const newKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

            // Recursively format nested objects
            newObj[newKey] = typeof value === 'object' && value !== null
                ? formatObjectKeysFromSnakeToCamelCase(value)
                : value;
        });

        return newObj;
    }

    return obj;
};