import React from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../../../../../constants/routes'
import { useSubscription } from '../../../../../components/context/SubscriptionContext'

interface SubscriptionValidatorProps {
  children: React.ReactElement
}

const SubscriptionValidator: React.FC<SubscriptionValidatorProps> = ({
  children,
}) => {
  const { isValidSubscription } = useSubscription()

  if (!isValidSubscription) {
    return <Navigate to={ROUTES.PAYMENT} replace />
  }

  return children
}

export default SubscriptionValidator
