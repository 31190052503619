import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSettings } from '../../../../../components/context/Settings'

type UserProfileSectionType = { [key: string]: string }
export type UserProfileAnswersType = {
  [key: string]: UserProfileSectionType
}

export const useUnsavedChangesModal = (hasUnsavedChanges: boolean) => {
  const { setSettings } = useSettings()
  useEffect(() => {
    const modalSettings = {
      hasUnsavedChanges,
      title: 'You Have Unsaved Changes',
      subTitle: 'Are you sure you want to leave without saving your changes?',
      continueEditButton: 'Continue Editing',
      leaveWithoutChangesButton: 'Leave Without Saving',
    }

    setSettings((prev) => ({
      ...prev,
      modal: hasUnsavedChanges
        ? { ...prev.modal, ...modalSettings }
        : { ...prev.modal, hasUnsavedChanges: false },
    }))
  }, [hasUnsavedChanges, setSettings])
}

export const useTradingProfileState = (
  initialProfile: UserProfileAnswersType
) => {
  const [answers, setAnswers] = useState<UserProfileAnswersType>(initialProfile)
  const [backup, setBackup] = useState<UserProfileAnswersType>(initialProfile)
  const backupSet = useRef(false)

  const isAnswersChanged = useMemo(
    () => JSON.stringify(answers) !== JSON.stringify(backup),
    [answers, backup]
  )

  const updateAnswers = useCallback(
    (newAnswers: UserProfileAnswersType) => setAnswers(newAnswers),
    []
  )

  const setInitialBackup = useCallback(() => {
    if (!backupSet.current) {
      setBackup(initialProfile)
      backupSet.current = true
    }
  }, [initialProfile])

  return {
    answers,
    isAnswersChanged,
    updateAnswers,
    setInitialBackup,
    setBackup,
  }
}
