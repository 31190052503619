import * as React from 'react'
import IconButton from '@mui/joy/IconButton'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { toggleSettingsSidebar } from './utils'

interface DefaultHeaderProps {
  title: string
}

const SettingsHeader: React.FC<DefaultHeaderProps> = ({ title }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.body',
        position: 'sticky',
        top: 0,
        zIndex: 10,
      }}
      py={{ xs: 2, md: 2 }}
      px={{ xs: 1, md: 1 }}
    >
      <Stack
        direction="row"
        spacing={{ xs: 1, md: 2 }}
        alignItems="center"
        margin="0.5em"
      >
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{
            display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' },
          }}
          onClick={() => toggleSettingsSidebar()}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
        <Typography fontWeight="lg" fontSize="lg" component="h2" noWrap>
          {title}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default SettingsHeader
