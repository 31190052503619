import * as React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import XIcon from '@mui/icons-material/X'
import Stack from '@mui/joy/Stack'
import IconButton from '@mui/joy/IconButton'
import { SxProps } from '@mui/material'

const SocialNetworks: React.FC = () => {
  const style: SxProps = {
    width: { xs: '20px', md: '30px' },
    height: { xs: '20px', md: '30px' },
  }

  return (
    <Stack flexDirection="row" justifyContent="center">
      <IconButton
        sx={style}
        color="neutral"
        onClick={() =>
          window.location.assign(
            'https://www.instagram.com/tradefulness?igsh=a3FxcTY5azUxNjNl&utm_source=qr'
          )
        }
      >
        <InstagramIcon sx={{ width: '100%', height: '100%' }} />
      </IconButton>
      <IconButton
        sx={style}
        color="neutral"
        onClick={() =>
          window.location.assign(
            'https://www.linkedin.com/company/tradefulness/'
          )
        }
      >
        <LinkedInIcon sx={{ width: '100%', height: '100%' }} />
      </IconButton>
      <IconButton
        sx={style}
        color="neutral"
        onClick={() =>
          window.location.assign(
            'https://www.facebook.com/profile.php?id=61555336212724'
          )
        }
      >
        <FacebookIcon sx={{ width: '100%', height: '100%' }} />
      </IconButton>
      <IconButton
        sx={style}
        color="neutral"
        onClick={() =>
          window.location.assign(
            'https://youtube.com/@tradefulness?si=48zQGKJrpg3USCxT'
          )
        }
      >
        <YouTubeIcon sx={{ width: '100%', height: '100%' }} />
      </IconButton>
      <IconButton
        sx={style}
        color="neutral"
        onClick={() =>
          window.location.assign('https://x.com/tradefulnessapp?s=11')
        }
      >
        <XIcon sx={{ width: '100%', height: '100%' }} />
      </IconButton>
    </Stack>
  )
}

export default SocialNetworks
