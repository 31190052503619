import * as React from 'react'
import { checkRegExpField, emailRegExp } from '../../../utils'
import { useSettings } from '../../../../../components/context/Settings'
import { useSocket } from '../../../../../components/context/SocketContext'
import { WEBSOCKET_EMIT_EVENTS } from '../../../../../../constants/api'
import { UserSettings } from '../../../../../types'
import { useUserSettings } from '../../../../../components/context/UserSettingsContext'

export const useUnsavedChangesModal = (hasUnsavedChanges: boolean) => {
  const { setSettings } = useSettings()

  React.useEffect(() => {
    const modalSettings = {
      hasUnsavedChanges,
      title: 'You Have Unsaved Changes',
      subTitle: 'Are you sure you want to leave without saving your changes?',
      continueEditButton: 'Continue Editing',
      leaveWithoutChangesButton: 'Leave Without Saving',
    }

    setSettings((prev) => ({
      ...prev,
      modal: hasUnsavedChanges
        ? { ...prev.modal, ...modalSettings }
        : { ...prev.modal, hasUnsavedChanges: false },
    }))
  }, [hasUnsavedChanges, setSettings])
}

export const useChangeFormHook = (initialUserSettings: UserSettings) => {
  const socket = useSocket()
  const [answers, setAnswers] =
    React.useState<UserSettings>(initialUserSettings)
  const [backup, setBackup] = React.useState<UserSettings>(initialUserSettings)
  const backupSet = React.useRef(false)
  const { setUserSettings } = useUserSettings()

  const isAnswersChanged = React.useMemo(
    () => JSON.stringify(answers) !== JSON.stringify(backup),
    [answers, backup]
  )

  const updateAnswers = React.useCallback(
    (newAnswers: UserSettings) => setAnswers(newAnswers),
    []
  )

  const setInitialBackup = React.useCallback(() => {
    if (!backupSet.current) {
      setBackup(initialUserSettings)
      backupSet.current = true
    }
  }, [initialUserSettings])

  const handleChangeName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const isValid = e.target.value.trim().length > 0
    const message = isValid ? '' : 'Please, enter your name!'

    updateAnswers({
      ...answers,
      name: { value: e.target.value, isValid, message },
    })
  }

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const isValid =
      e.target.value.trim().length > 0 &&
      checkRegExpField(e.target.value, emailRegExp)
    const message = isValid ? '' : 'Please, enter your email!'

    updateAnswers({
      ...answers,
      email: { value: e.target.value, isValid, message },
    })
  }

  const handleClickSave: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (socket) {
      socket.emit(
        WEBSOCKET_EMIT_EVENTS.SAVE_ACCOUNT,
        {
          email: answers.email.value,
          name: answers.name.value,
          language: answers.language,
        },
        () => {
          setBackup(answers)
          setUserSettings((prev) => answers)
        }
      )
    }
  }

  const isFormValid = React.useMemo(() => {
    return answers.name.isValid && answers.email.isValid
  }, [answers.email.isValid, answers.name.isValid])

  return {
    answers,
    handleChangeName,
    handleChangeEmail,
    handleClickSave,
    isFormValid,
    setInitialBackup,
    isAnswersChanged,
    setBackup,
  }
}
