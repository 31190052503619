import Typography from '@mui/joy/Typography'
import Card from '@mui/joy/Card'
import Divider from '@mui/joy/Divider'
import { useCallback, useEffect } from 'react'
import { Box, Button, Stack } from '@mui/joy'
import { useTradingProfile } from '../../../../../components/context/TradingProfileContext'
import { useSocket } from '../../../../../components/context/SocketContext'
import { WEBSOCKET_EMIT_EVENTS } from '../../../../../../constants/api'
import {
  useTradingProfileState,
  useUnsavedChangesModal,
} from './TradingProfileCard.hooks'
import { TradingProfileSection } from './TradingProfileSection'
import { formatObjectKeysFromCamelToSnakeCase } from '../../../../../../util/utils'

export type SelectAnswerType = (
  selectedValue: string | string[] | null,
  selectedFieldKey: string,
  sectionTitle: string
) => void

export const TradingProfileCard = () => {
  const { tradingProfile } = useTradingProfile()
  const socket = useSocket()

  const {
    answers,
    isAnswersChanged,
    updateAnswers,
    setInitialBackup,
    setBackup,
  } = useTradingProfileState(tradingProfile)

  useUnsavedChangesModal(isAnswersChanged)

  useEffect(() => {
    setInitialBackup()
  }, [setInitialBackup])

  const handleSelectAnswer: SelectAnswerType = useCallback(
    (selectedValue, selectedFieldKey, sectionTitle) => {
      const updatedSection = {
        ...answers[sectionTitle],
        [selectedFieldKey]:
          typeof selectedValue === 'string'
            ? selectedValue
            : selectedValue?.join(', ') || '',
      }

      updateAnswers({ ...answers, [sectionTitle]: updatedSection })
    },
    [answers, updateAnswers]
  )

  const handleClickSave = () => {
    if (socket) {
      const request = formatObjectKeysFromCamelToSnakeCase(answers)
      socket.emit(WEBSOCKET_EMIT_EVENTS.SAVE_TRADING_PROFILE, request, () => {
        setBackup(answers)
      })
    }
  }

  return (
    <Card sx={{ flex: 1, width: '100%' }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Trading Profile</Typography>
        <Typography level="body-sm">
          Customize your trading profile information.
        </Typography>
      </Box>
      <Divider />
      <Stack
        spacing={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          alignItems: 'start',
        }}
      >
        {Object.entries(tradingProfile).map(([sectionTitle, sectionFields]) => (
          <TradingProfileSection
            key={sectionTitle}
            sectionTitle={sectionTitle}
            sectionFields={sectionFields}
            answers={answers}
            handleSelectAnswer={handleSelectAnswer}
          />
        ))}
      </Stack>
      <Divider />
      <Button
        size="sm"
        variant="solid"
        disabled={!isAnswersChanged}
        onClick={handleClickSave}
        sx={{ alignSelf: 'flex-end' }}
      >
        Save
      </Button>
    </Card>
  )
}
