import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSocket } from './SocketContext'
import { WEBSOCKET_RECEIVE_EVENTS } from '../../../constants/api'
import { TradingProfile } from '../../app/settings/types'
import { formatObjectKeysFromSnakeToCamelCase } from '../../../util/utils'

const TradingProfileContext = createContext({
  tradingProfile: {} as TradingProfile,
  setTradingProfile: (prev: TradingProfile) => {},
})

export const TradingProfileProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const socket = useSocket()
  const [tradingProfile, setTradingProfile] = useState<TradingProfile>(
    {} as TradingProfile
  )

  useEffect(() => {
    const userProfileListener = (response: string) => {
      const userProfileResponse = JSON.parse(response)
      console.log(userProfileResponse)
      setTradingProfile(
        formatObjectKeysFromSnakeToCamelCase(userProfileResponse.profile)
      )
    }
    if (socket) {
      socket.on(WEBSOCKET_RECEIVE_EVENTS.USER_PROFILE, userProfileListener)
    }

    return () => {
      if (socket) {
        socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.USER_PROFILE)
      }
    }
  }, [socket])

  return (
    <TradingProfileContext.Provider
      value={{ tradingProfile, setTradingProfile }}
    >
      {children}
    </TradingProfileContext.Provider>
  )
}

export const useTradingProfile = () => {
  return useContext(TradingProfileContext)
}
