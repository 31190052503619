import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSocket } from './SocketContext'
import { WEBSOCKET_RECEIVE_EVENTS } from '../../../constants/api'
import { UserSettings, UserSettingsResponse } from '../../types'

const UserSettingsContext = createContext({
  userSettings: {
    email: { value: '', isValid: true, message: '' },
    name: { value: '', isValid: true, message: '' },
    language: '',
    quiz_finished: true,
    picture: '',
    voice_response_enabled: false,
  },
  setUserSettings: (prev: (prev: UserSettings) => UserSettings) => {},
  isSettingsLoaded: false,
})

export const UserSettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const socket = useSocket()
  const [isSettingsLoaded, setIsSettingsLoaded] = useState<boolean>(false)
  const [userSettings, setUserSettings] = useState<UserSettings>({
    email: { value: '', isValid: true, message: '' },
    name: { value: '', isValid: true, message: '' },
    language: '',
    quiz_finished: true,
    picture: '',
    voice_response_enabled: false,
  })

  useEffect(() => {
    const userSettingsListener = (response: string) => {
      const userSettingsResponse: UserSettingsResponse = JSON.parse(response)
      console.log(userSettingsResponse)
      setIsSettingsLoaded(true)
      setUserSettings((prev) => ({
        ...userSettingsResponse,
        name: { value: userSettingsResponse.name, isValid: true, message: '' },
        email: {
          value: userSettingsResponse.email,
          isValid: true,
          message: '',
        }
      }))
    }
    if (socket) {
      socket.on(WEBSOCKET_RECEIVE_EVENTS.USER_SETTINGS, userSettingsListener)
    }

    return () => {
      if (socket) {
        socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.USER_SETTINGS)
      }
    }
  }, [socket])

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings,
        setUserSettings,
        isSettingsLoaded
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}

export const useUserSettings = () => {
  return useContext(UserSettingsContext)
}
