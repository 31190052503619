import * as React from 'react'
import Box from '@mui/joy/Box'
import { QuizScreensProps, SwipeableOptions } from './types'
import { useUserSettings } from '../../components/context/UserSettingsContext'
import { useTradingProfile } from '../../components/context/TradingProfileContext'
import { TradingProfile } from '../settings/types'
import Screen from './screens/Screen'
import Intro from './screens/Intro'
import Finish from './screens/Finish'

const QuizScreens: React.FC<QuizScreensProps> = ({
  quizQuestions,
  currentStep,
  setCurrentStep,
  tradingProfile,
}) => {
  const { setUserSettings } = useUserSettings()
  const { setTradingProfile } = useTradingProfile()
  const currentScreenTransition = '0%'
  const xsTransition = '250'
  const smTransition = '300'
  const mdTransition = '350'
  const lgTransition = '400'
  const handleNext = () => {
    if (currentStep < quizQuestions.length - 1) {
      setCurrentStep(currentStep + 1)
    } else if (currentStep === quizQuestions.length - 1) {
      setTradingProfile(tradingProfile)
      setUserSettings((prev) => {
        return {
          ...prev,
          quiz_finished: true,
        }
      })
    }
  }
  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }
  const swipeableOptionsPromise = (
    isScreenCompleted: boolean,
    processProfile: (profile: TradingProfile) => void
  ): SwipeableOptions => ({
    onSwipedLeft: () => {
      if (isScreenCompleted) {
        processProfile(tradingProfile)
        handleNext()
      }
    },
    onSwipedRight: () => {
      handlePrevious()
    },
    trackTouch: true,
  })

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      {quizQuestions.map((quizQuestion, index) => {
        const transition = (transitionValue: string) => {
          return currentStep > index
            ? `-${transitionValue}%`
            : currentStep < index
            ? `${transitionValue}%`
            : currentScreenTransition
        }
        return (
          <Box
            key={index}
            sx={{
              height: { xs: '75%', sm: '80%' },
              display: 'flex',
              maxWidth: '33em',
              margin: '1em',
              paddingX: '1em',
              position: 'fixed',
              transform: {
                xs: `translate(${transition(xsTransition)})`,
                sm: `translate(${transition(smTransition)})`,
                md: `translate(${transition(mdTransition)})`,
                lg: `translate(${transition(lgTransition)})`,
              },
              transition: 'transform 0.7s',
            }}
          >
            {quizQuestion.id === 'intro' ? (
              <Intro optionsPromise={swipeableOptionsPromise} />
            ) : quizQuestion.id === 'finish' ? (
              <Finish optionsPromise={swipeableOptionsPromise} />
            ) : (
              <Screen
                key={quizQuestion.id}
                quizQuestion={quizQuestion}
                optionsPromise={swipeableOptionsPromise}
              />
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export default QuizScreens
