import * as React from 'react'
import GlobalStyles from '@mui/joy/GlobalStyles'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Typography from '@mui/joy/Typography'
import Stack from '@mui/joy/Stack'
import GoogleIcon from './GoogleIcon'
import { API_CONST } from '../../constants/api'
import { useGoogleLogin } from '@react-oauth/google'
import SocialNetworks from '../components/SocialNetworks'
import LoginHeader from '../components/LoginHeader'
import { useAuth } from '../components/context/AuthContext'
import {
  BlackFullVerticalWithTextIcon,
  WhiteFullVerticalWithTextIcon,
} from '../components/Logo'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useColorScheme } from '@mui/joy'

// interface FormElements extends HTMLFormControlsCollection {
//   email: HTMLInputElement
//   password: HTMLInputElement
//   persistent: HTMLInputElement
// }

// interface SignInFormElement extends HTMLFormElement {
//   readonly elements: FormElements
// }

const Login: React.FC = () => {
  const { login } = useAuth()
  const { mode } = useColorScheme()

  const getJwtToken = async (code: string, ref: string | null) => {
    const response = await fetch(API_CONST.GOOGLE_AUTH_ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        code: code,
        ref: ref,
      }),
    })
    await response.json().then((value) => {
      console.log(value.quiz_finished)
      login(value.token, value.quiz_finished)
    })
  }

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      const urlParams = new URLSearchParams(window.location.search)
      const ref = urlParams.get('ref')
      await getJwtToken(codeResponse.code, ref)
    },
  })

  // const onLoginFormSubmit = async (
  //   event: React.FormEvent<SignInFormElement>
  // ) => {
  //   event.preventDefault()
  //   setIsDisabledButton(true)
  //   const formElements = event.currentTarget.elements

  //   try {
  //     const response = await axios.get(API_CONST.AUTH_ENDPOINT, {
  //       auth: {
  //         username: formElements.email.value,
  //         password: formElements.password.value,
  //       },
  //     })
  //     login(response.data.token, response.data.quiz_finished)
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       let message
  //       if (error.response && error.response.status === 401) {
  //         message = 'Invalid username or password'
  //       } else {
  //         message = 'Server error'
  //       }
  //       setAlert(message)
  //     } else {
  //       throw error
  //     }
  //   } finally {
  //     setIsDisabledButton(false)
  //   }
  // }

  return (
    <>
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s',
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: '100%',
          minHeight: '100dvh',
          margin: { xs: '0px', md: '0 auto' },
          transition: 'backgroundColor var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
          gap: { md: 4 },
          justifyContent: 'center',
          backdropFilter: 'blur(120px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: { xs: '60dvh', md: '100dvh' },
            width: { xs: '100%', md: '50%' },
            padding: {
              xs: '0 20px 20px',
              sm: '0 30px 30px',
              md: '30px 0 30px 30px',
              xl: '60px 20px 60px 60px',
            },
            justifyContent: 'space-between',
          }}
        >
          <LoginHeader />
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: { xs: '8px' },
              gap: 4,
            }}
          >
            <Stack sx={{ marginBottom: { md: 4 } }}>
              <Typography
                component="h1"
                fontSize={{
                  xs: '25px',
                  sm: '28px',
                  md: '32px',
                  lg: '48px',
                  xl: '54px',
                }}
                fontWeight={500}
                textAlign="center"
              >
                Welcome to Tradefulness
              </Typography>
              <Typography
                component="h3"
                fontSize={{
                  xs: '25px',
                  sm: '28px',
                  md: '32px',
                  lg: '48px',
                  xl: '54px',
                }}
                fontWeight={500}
                textColor="#939393"
                textAlign="center"
              >
                Your trading AI assistant
              </Typography>
            </Stack>
            <Typography
              fontSize={{ xs: '14px', md: '22px', lg: '28px' }}
              fontWeight={500}
              textColor="#939393"
              textAlign="center"
            >
              Activate free trial period to explore Tradefulness
            </Typography>
            <Stack sx={{ px: { md: 5, lg: 10, xl: 15 }, width: '100%' }}>
              <Button
                variant="soft"
                color="neutral"
                startDecorator={<GoogleIcon />}
                onClick={() => googleLogin()}
                fullWidth
                size="lg"
                sx={{
                  fontSize: { xs: '14px', md: '24px', lg: '28px' },
                  fontWeight: '500px',
                  alignItems: 'center',
                }}
              >
                Continue with Google
              </Button>
            </Stack>
            <Typography
              fontSize={{ xs: '14px', md: '16px', lg: '24px' }}
              fontWeight={500}
              textColor="#939393"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              <ErrorOutlineIcon />
              Type TPC in a chat and receive a Set-Up Kit
            </Typography>
          </Box>
          <Box
            component="footer"
            sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 0.5 } }}
          >
            <SocialNetworks />
            <Typography level="body-xs" textAlign="center" textColor="#939393">
              By signing up , you agree to the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/13MaYneFcM5yuGCsY04afL3TWvt-YQNG_/edit?usp=sharing&ouid=107810029294470927679&rtpof=true&sd=true"
                style={{
                  textDecoration: 'none',
                  color: mode === 'light' ? '#32383E' : '#CDD7E1',
                  fontWeight: 'normal',
                  cursor: 'pointer',
                }}
              >
                Terms of Use
              </a>
              ,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/13E47JP3eJrVJ0lxbPBjDEQjPAGM4Ih87/edit?usp=sharing&ouid=107810029294470927679&rtpof=true&sd=true"
                style={{
                  textDecoration: 'none',
                  color: mode === 'light' ? '#32383E' : '#CDD7E1',
                  fontWeight: 'normal',
                  cursor: 'pointer',
                }}
              >
                Privacy Notice
              </a>
              , and{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/13BdH42P1DwowEmIGkAyaigoGJkEn1ouC/edit?usp=sharing&ouid=107810029294470927679&rtpof=true&sd=true"
                style={{
                  textDecoration: 'none',
                  color: mode === 'light' ? '#32383E' : '#CDD7E1',
                  fontWeight: 'normal',
                  cursor: 'pointer',
                }}
              >
                Cookie Notice
              </a>
            </Typography>
            <Typography level="body-xs" textAlign="center">
              © Tradefulness AI {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: { xs: '40dvh', md: '100dvh' },
            width: { xs: 'auto', md: '50%' },
            padding: {
              xs: '20px 20px ',
              sm: '30px 30px',
              md: '30px 30px 30px 0 ',
              xl: '60px 60px 60px 0',
            },
            position: 'relative',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 'none',
              border: 'none',
              backgroundColor: 'background.level1',
              overflow: 'hidden',
            }}
          >
            <img
              src={
                mode === 'light'
                  ? '/login_light_background2.png'
                  : '/login_dark_background2.png'
              }
              alt="login"
              height="100%"
              width="100%"
              style={{
                borderRadius: '12px',
                filter: 'blur(2.5px)',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: 0,
              left: 0,
            }}
          >
            {mode === 'light' ? (
              <BlackFullVerticalWithTextIcon
                sx={{ width: '30%', height: '30%' }}
              />
            ) : (
              <WhiteFullVerticalWithTextIcon
                sx={{ width: '30%', height: '30%' }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Login
