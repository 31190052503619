import { Checkbox, Input, RadioGroup } from '@mui/joy'
import { QuizQuestionType } from '../types'

type MultipleRadioGroupType = {
  quizQuestion: QuizQuestionType
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  selectedValues: string[]
}

export const MultipleRadioGroup: React.FC<MultipleRadioGroupType> = ({
  quizQuestion,
  setSelectedValues,
  setInputValue,
  selectedValues,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setSelectedValues((prevValues) =>
      prevValues.includes(value)
        ? prevValues.filter((v) => v !== value)
        : [...prevValues, value]
    )
  }
  return (
    <RadioGroup
      name="radio-buttons-group"
      value={selectedValues}
      sx={{
        gap: 2,
      }}
    >
      {quizQuestion.answers.map((answer) => {
        return (
          <Checkbox
            key={answer.value}
            label={answer.label}
            value={answer.value}
            onChange={handleChange}
          />
        )
      })}
      {quizQuestion.isOthers && (
        <Input
          placeholder="Other..."
          onChange={(event) => setInputValue(event.target.value)}
        />
      )}
    </RadioGroup>
  )
}
