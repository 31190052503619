import Typography from '@mui/joy/Typography'
import Select, { selectClasses } from '@mui/joy/Select'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Option from '@mui/joy/Option'
import { Input, ListItem, Stack } from '@mui/joy'
import { formatCamelCaseToHumanReadable } from '../../../utils'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SelectAnswerType } from './TradingProfileCard'

interface SelectOption {
  value: string
  label: string
}

interface TradingProfileCardItemProps {
  title: string
  selectOptions: SelectOption[]
  isMultiple: boolean
  value: string | string[]
  otherValue: string
  sectionTitle: string
  selectAnswer: SelectAnswerType
}

export const TradingProfileCardItem: FC<TradingProfileCardItemProps> = ({
  sectionTitle,
  title,
  selectOptions,
  isMultiple,
  value,
  otherValue,
  selectAnswer,
}) => {
  const [otherInputState, setOtherInputState] = useState({
    value: '',
    isValid: true,
    message: '',
  })
  const isOtherSelected = useMemo(
    () => value === 'other' || value?.includes('other'),
    [value]
  )

  useEffect(() => {
    const isValid = otherValue.trim().length > 0
    setOtherInputState({
      value: otherValue,
      isValid,
      message: isValid ? '' : 'Please, enter any value.',
    })
  }, [otherValue])

  const handleInputOther = useCallback(
    (inputValue: string) => {
      const isValid = inputValue.trim().length > 0
      setOtherInputState({
        value: inputValue,
        isValid,
        message: isValid ? '' : 'Please, enter a value.',
      })

      if (isMultiple && Array.isArray(value)) {
        const updatedValues = value.map((v) => (v === 'other' ? inputValue : v))
        selectAnswer(updatedValues, title, sectionTitle)
      } else {
        selectAnswer(inputValue, title, sectionTitle)
      }
    },
    [isMultiple, sectionTitle, selectAnswer, title, value]
  )

  const handleSelectChange = useCallback(
    (
      e: React.SyntheticEvent | null,
      selectedValue: string | string[] | null
    ) => {
      if (isMultiple && Array.isArray(selectedValue)) {
        const selectedAnswerArr = selectedValue.map((v) => {
          return v === 'other' ? otherInputState.value : v
        })
        selectAnswer(selectedAnswerArr, title, sectionTitle)
      } else {
        const selectedAnswer =
          selectedValue === 'other' ? otherInputState.value : selectedValue
        selectAnswer(selectedAnswer, title, sectionTitle)
      }
    },
    [isMultiple, selectAnswer, title, sectionTitle, otherInputState.value]
  )

  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'center', md: 'start' },
      }}
    >
      <Typography
        level="body-sm"
        sx={{
          color: 'var(--joy-palette-text-primary)',
          flex: { xs: 1.2, sm: 1 },
          width: { xs: '100%', md: 'auto' },
          paddingBottom: { xs: 1, md: 'none' },
        }}
      >
        {formatCamelCaseToHumanReadable(title)}:
      </Typography>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '100%', md: '70%' },
        }}
      >
        <Select
          placeholder="Select your answer"
          indicator={<KeyboardArrowDown />}
          sx={{
            width: '100%',
            borderColor: 'divider',
            borderRadius: 'sm',
            p: 1,
            pl: 1.5,
            color: 'text.secondary',
            boxShadow: 'none',
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
          }}
          value={value}
          onChange={handleSelectChange}
          multiple={isMultiple}
        >
          {selectOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {isOtherSelected && (
          <Input
            required={true}
            value={otherInputState.value}
            onChange={(e) => handleInputOther(e.target.value)}
            error={!otherInputState.isValid}
            color={!otherInputState.isValid ? 'danger' : 'neutral'}
            placeholder={
              otherInputState.isValid
                ? 'Write oter here...'
                : otherInputState.message
            }
            sx={{
              width: '100%',
              borderColor: 'divider',
              borderRadius: 'sm',
              p: 1,
              pl: 1.5,
              mt: 2,
              color: 'text.secondary',
              boxShadow: 'none',
            }}
          />
        )}
      </Stack>
    </ListItem>
  )
}
