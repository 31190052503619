import React, { ReactNode } from 'react'
import { SocketProvider } from './SocketContext'
import { AudioOutputControlsProvider } from './AudioOutputControlsContext'
import { UserSettingsProvider } from './UserSettingsContext'
import { ChatHistoryProvider } from './ChatHistoryContext'
import { AudioInputControlsProvider } from './AudioInputControlsContext'
import { GOOGLE_CLIENT_ID } from '../../app/const'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { TradingProfileProvider } from './TradingProfileContext'
import { AuthProvider } from './AuthContext'
import { SubscriptionProvider } from './SubscriptionContext'
import { SettingsProvider } from './Settings'

export const ContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <AuthProvider>
      <SocketProvider>
        <AudioOutputControlsProvider>
          <AudioInputControlsProvider>
            <UserSettingsProvider>
              <TradingProfileProvider>
                <ChatHistoryProvider>
                  <SettingsProvider>
                    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                      <SubscriptionProvider>{children}</SubscriptionProvider>
                    </GoogleOAuthProvider>
                  </SettingsProvider>
                </ChatHistoryProvider>
              </TradingProfileProvider>
            </UserSettingsProvider>
          </AudioInputControlsProvider>
        </AudioOutputControlsProvider>
      </SocketProvider>
    </AuthProvider>
  )
}
