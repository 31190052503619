import {
  Box,
  Card,
  GlobalStyles,
  IconButton,
  List,
  ListItem,
  ListItemContent,
  Sheet,
  Stack,
  Typography,
} from '@mui/joy'
import { FC, useState } from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import AccountCard from './components/cards/account/AccountCard'
import QuizIcon from '@mui/icons-material/Quiz'
import { TradingProfileCard } from './components/cards/trading-profile/TradingProfileCard'
import { useSettings } from '../../components/context/Settings'
import SubscriptionCard from './components/cards/subscription/SubscriptionCard'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { closeSettingsSidebar, toggleSettingsSidebar } from './utils'
import SettingsHeader from './SettingsHeader'
import { toggleSidebar } from '../sidebar/utils'
import CloseIcon from '@mui/icons-material/Close'
import { useUserSettings } from '../../components/context/UserSettingsContext'

const SETTINGS_ITEMS = {
  // GENERAL: 'General', TODO: add general settings later
  ACCOUNT: 'Account',
  TRADING_PROFILE: 'Trading Profile',
  SUBSCRIPTION: 'Subscription',
}

const settingsSections = [
  // { id: 'General', icon: <SettingsIcon sx={{ fontSize: 18 }} /> }, TODO: add general settings later
  { id: 'Account', icon: <AccountBoxIcon sx={{ fontSize: 18 }} /> },
  { id: 'Trading Profile', icon: <QuizIcon sx={{ fontSize: 18 }} /> },
  { id: 'Subscription', icon: <CardMembershipIcon sx={{ fontSize: 18 }} /> },
]

export const Settings: FC = () => {
  const [section, setSection] = useState('Account')
  const { settings, setSettings } = useSettings()
  const { userSettings, setUserSettings } = useUserSettings()

  const handleClickSidebarLink = (id: string) => {
    if (settings.modal.hasUnsavedChanges) {
      setSettings((prev) => ({
        ...prev,
        modal: {
          ...prev.modal,
          isModalOpen: true,
          onClickLeaveWithoutChangesButton: () => {
            setUserSettings((prev) => ({ ...prev, ...userSettings }))
            setSection(id)
          },
          onClickReturnAndSave: () => {
            setSettings((prev) => ({
              ...prev,
              modal: { ...prev.modal, isModalOpen: false },
            }))
          },
        },
      }))
    } else {
      setSection(id)
    }
    toggleSettingsSidebar()
  }

  return (
    <>
      <SettingsHeader title="Settings" />
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 3,
          width: '100%',
          alignSelf: 'center',
          gap: 4,
        }}
      >
        <Sheet
          className="SettingsSidebar"
          sx={{
            position: { xs: 'fixed', md: 'sticky' },
            transform: {
              xs: 'translateX(calc(100% * (var(--SideSettingsNavigation-slideIn, 0) - 1.11)))',
              md: 'none',
            },
            transition: 'transform 0.4s, width 0.4s',
            width: 'var(--SettingsSidebar-width)',
            zIndex: { xs: 11, md: 9 },
            top: 0,
            p: 2,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid',
            borderColor: 'divider',
            minHeight: { xs: '100dvh', md: 'calc(100dvh - 124px)' },
          }}
        >
          <GlobalStyles
            styles={(theme) => ({
              ':root': {
                '--SettingsSidebar-width': '220px',
                [theme.breakpoints.up('lg')]: {
                  '--SettingsSidebar-width': '240px',
                },
              },
            })}
          />
          <Box
            className="SettingsSidebar-overlay"
            sx={{
              display: { xs: 'inline-flex', md: 'none' },
              position: 'fixed',
              zIndex: 8,
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              opacity: 'var(--SideSettingsNavigation-slideIn)',
              backgroundColor: 'var(--joy-palette-background-backdrop)',
              transition: 'opacity 0.4s',
              transform: {
                xs: 'translateX(calc(100% * (var(--SideSettingsNavigation-slideIn, 0) - 1) + var(--SideSettingsNavigation-slideIn, 0) * var(--SettingsSidebar-width, 0px)))',
                lg: 'translateX(-100%)',
              },
            }}
            onClick={() => closeSettingsSidebar()}
          />

          <Box
            sx={{
              overflow: 'hidden auto',
              height: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              [`& .${listItemButtonClasses.root}`]: {
                gap: 1.5,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 2,
              }}
            >
              <IconButton
                variant="plain"
                color="neutral"
                size="sm"
                sx={{
                  display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' },
                }}
                onClick={() => {
                  toggleSettingsSidebar()
                  toggleSidebar()
                }}
              >
                <ArrowBackIosNewRoundedIcon />
              </IconButton>
              <IconButton
                variant="plain"
                color="neutral"
                size="sm"
                sx={{
                  display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' },
                }}
                onClick={() => toggleSettingsSidebar()}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <List
              size="sm"
              sx={{
                gap: 2,
                '--List-nestedInsetStart': '30px',
                '--ListItem-radius': (theme) => theme.vars.radius.sm,
              }}
            >
              {settingsSections.map((sett) => (
                <ListItem key={sett.id}>
                  <ListItemButton
                    selected={section === sett.id}
                    onClick={() => handleClickSidebarLink(sett.id)}
                  >
                    <ListItemContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {sett.icon}
                      <Typography fontSize={14} sx={{ marginLeft: '8px' }}>
                        {sett.id}
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Sheet>
        <Card
          sx={{
            width: '100%',
          }}
        >
          {section === SETTINGS_ITEMS.ACCOUNT && <AccountCard />}
          {section === SETTINGS_ITEMS.TRADING_PROFILE && <TradingProfileCard />}
          {section === SETTINGS_ITEMS.SUBSCRIPTION && <SubscriptionCard />}
        </Card>
      </Stack>
    </>
  )
}
