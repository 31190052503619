import * as React from 'react'
import { useState } from 'react'
import Typography from '@mui/joy/Typography'
import Stack from '@mui/joy/Stack'
import Button from '@mui/joy/Button'
import { SwipeableOptionsProps } from '../types'
import { Radio, RadioGroup } from '@mui/joy'
import { useSwipeable } from 'react-swipeable'
import Input from '@mui/joy/Input'
import { MultipleRadioGroup } from './MultipleRadioGroup'

const Screen = ({ optionsPromise, quizQuestion }: SwipeableOptionsProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('')
  const [inputValue, setInputValue] = useState('')
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  const isComponentReady = React.useMemo(
    () =>
      quizQuestion.isMultiple
        ? selectedValues.length !== 0 || inputValue.trim() !== ''
        : !quizQuestion.isOthers
        ? selectedValue !== ''
        : selectedValue !== '' &&
          (selectedValue !== 'input' ||
            (selectedValue === 'input' && inputValue.trim() !== '')),
    [
      inputValue,
      quizQuestion.isMultiple,
      quizQuestion.isOthers,
      selectedValue,
      selectedValues.length,
    ]
  )

  const joinValues = React.useMemo(
    () => () => {
      const values = [...selectedValues, inputValue]
      return values.filter((str) => str.trim() !== '').join(', ')
    },
    [inputValue, selectedValues]
  )

  const swipeableOptions = React.useMemo(
    () =>
      optionsPromise(
        isComponentReady,
        (profile) =>
          (profile[quizQuestion.topic] = {
            ...profile[quizQuestion.topic],
            [quizQuestion.id]: quizQuestion.isMultiple
              ? joinValues()
              : selectedValue === 'input'
              ? inputValue
              : selectedValue,
          })
      ),
    [
      inputValue,
      isComponentReady,
      joinValues,
      optionsPromise,
      quizQuestion.id,
      quizQuestion.isMultiple,
      quizQuestion.topic,
      selectedValue,
    ]
  )

  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value)
  }

  return (
    <Stack
      {...useSwipeable(swipeableOptions)}
      direction="column"
      justifyContent="space-around"
    >
      <Stack direction="column" gap={7}>
        <Typography level="h3" textAlign="center">
          {quizQuestion.question}
        </Typography>
        {quizQuestion.isMultiple ? (
          <MultipleRadioGroup
            quizQuestion={quizQuestion}
            setSelectedValues={setSelectedValues}
            setInputValue={setInputValue}
            selectedValues={selectedValues}
          />
        ) : (
          <RadioGroup
            name="radio-buttons-group"
            sx={{
              gap: 1,
            }}
            value={selectedValue}
            onChange={handleRadioChange}
          >
            {quizQuestion.answers.map((answer) => {
              return (
                <Radio
                  key={answer.value}
                  value={answer.value}
                  label={answer.label}
                />
              )
            })}
            {quizQuestion.isOthers && (
              <Stack direction="row" mt={1} key={quizQuestion.id + '-input'}>
                <Radio value="input" checked={selectedValue === 'input'} />
                <Input
                  onChange={(event) => setInputValue(event.target.value)}
                  onClick={() => setSelectedValue('input')}
                  placeholder="Other..."
                  sx={{ ml: 1.5 }}
                />
              </Stack>
            )}
          </RadioGroup>
        )}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <Button
          variant="outlined"
          sx={{
            width: 80,
          }}
          onClick={swipeableOptions.onSwipedRight}
        >
          Back
        </Button>
        <Button
          sx={{
            width: 80,
          }}
          onClick={() => {
            swipeableOptions.onSwipedLeft()
          }}
          disabled={!isComponentReady}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  )
}

export default Screen
