import * as React from 'react'
import Typography from '@mui/joy/Typography'
import Stack from '@mui/joy/Stack'
import Button from '@mui/joy/Button'
import { useSwipeable } from 'react-swipeable'
import { SwipeableOptions } from '../types'
import { TradingProfile } from '../../settings/types'

const Intro = ({
  optionsPromise,
}: {
  optionsPromise: (
    isScreenCompleted: boolean,
    updateProfile: (profile: TradingProfile) => void
  ) => SwipeableOptions
}) => {
  const swipeableOptions = optionsPromise(true, (profile) => {})

  return (
    <Stack
      direction="column"
      justifyContent="center"
      gap={3}
      mt="-5em"
      {...useSwipeable(swipeableOptions)}
    >
      <Typography component="h3" level="h3" textAlign="center">
        Let me ask some questions to know you better
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          pt: 2,
          gap: 2,
        }}
      >
        <Button
          variant="outlined"
          size="lg"
          onClick={swipeableOptions.onSwipedLeft}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}

export default Intro
