export enum Author {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export type AttachmentInfo = {
  name: string
  size: string
}

export interface IMessage {
  message: string
  timestamp: string
  author: Author
  attachment?: AttachmentInfo
}

export class UserMessage implements IMessage {
  message: string
  timestamp: string
  author: Author
  attachment?: AttachmentInfo

  constructor(message: string, timestamp: string, attachment?: AttachmentInfo) {
    this.author = Author.USER
    this.message = message
    this.timestamp = timestamp
    this.attachment = attachment
  }
}

export class AssistantMessage implements IMessage {
  message: string
  timestamp: string
  author: Author

  constructor(message: string, timestamp: string) {
    this.author = Author.ASSISTANT
    this.message = message
    this.timestamp = timestamp
  }
}

export type ChatHistory = {
  messages: IMessage[]
}

export type AudioInputControls = {
  audioContext: AudioContext | null
  workletNode: AudioWorkletNode | null
  sourceNode: MediaStreamAudioSourceNode | null
  channelSplitterNode: ChannelSplitterNode | null
  channelMergerNode: ChannelMergerNode | null
}

export type AudioOutputSettings = {
  isPlaying: boolean
}

export type AudioOutputControls = {
  isPlaying: boolean
  audioContext: AudioContext | null
  pendingChunks: ArrayBuffer[]
  sourceNode: AudioBufferSourceNode | null
  gainNode: GainNode | null
}

export type UserSettings = {
  name: { value: string; isValid: boolean; message: string }
  email: { value: string; isValid: boolean; message: string }
  language: string
  picture: string
  quiz_finished: boolean
  voice_response_enabled: boolean
}

export type UserSettingsResponse = {
  name: string
  email: string
  language: string
  picture: string
  quiz_finished: boolean
  voice_response_enabled: boolean
}

export type Settings = {
  modal: {
    hasUnsavedChanges: boolean
    isModalOpen: boolean
    title: string
    subTitle: string
    leaveWithoutChangesButton: string
    continueEditButton: string
    onClickReturnAndSave: () => void
    onClickLeaveWithoutChangesButton: () => void
  }
}
