import * as React from 'react'
import { useEffect, useState } from 'react'
import GlobalStyles from '@mui/joy/GlobalStyles'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import Chip from '@mui/joy/Chip'
import Divider from '@mui/joy/Divider'
import IconButton from '@mui/joy/IconButton'
import Input from '@mui/joy/Input'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'
import Sheet from '@mui/joy/Sheet'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import SupportRoundedIcon from '@mui/icons-material/SupportRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import MenuBookIcon from '@mui/icons-material/MenuBook'

import ColorSchemeToggle from './ColorSchemeToggle'
import { closeSidebar } from './utils'
import { useColorScheme } from '@mui/joy/styles'
import { BlackSmallIcon, WhiteSmallIcon } from '../../components/Logo'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import { useSocket } from '../../components/context/SocketContext'
import { useUserSettings } from '../../components/context/UserSettingsContext'
import { useChatHistory } from '../../components/context/ChatHistoryContext'
import SocialNetworks from '../../components/SocialNetworks'
import { useAuth } from '../../components/context/AuthContext'
import Stack from '@mui/joy/Stack'
import { addDays } from 'date-fns'
import Card from '@mui/joy/Card'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Button from '@mui/joy/Button'
import { useSubscription } from '../../components/context/SubscriptionContext'
import { LinearProgress } from '@mui/joy'
import { isBeforeOrEquals } from '../../../util/utils'
import { useSettings } from '../../components/context/Settings'

const Sidebar: React.FC = () => {
  const { logout } = useAuth()
  const location = useLocation()
  const socket = useSocket()
  const { userSettings, setUserSettings } = useUserSettings()
  const { settings, setSettings } = useSettings()
  const { chatHistory } = useChatHistory()
  const { mode } = useColorScheme()
  const { activeSubscription, isValidSubscription, futureSubscriptions } =
    useSubscription()
  const navigate = useNavigate()
  const [
    isSubscriptionExpirationNotificationShown,
    setSubscriptionExpirationNotificationShown,
  ] = useState(false)

  useEffect(() => {
    const currentDatePlusThree = addDays(new Date(), 3)
    const subscriptionExpirationSoon = activeSubscription
      ? isBeforeOrEquals(activeSubscription.end_date, currentDatePlusThree)
      : false
    const noFutureSubscriptions = futureSubscriptions.length === 0
    setSubscriptionExpirationNotificationShown(
      subscriptionExpirationSoon && noFutureSubscriptions
    )
  }, [socket, activeSubscription, futureSubscriptions])

  const handleClickSidebarLink = (route: string) => {
    if (settings.modal.hasUnsavedChanges) {
      setSettings((prev) => ({
        ...prev,
        modal: {
          ...prev.modal,
          isModalOpen: true,
          onClickLeaveWithoutChangesButton: () => {
            setUserSettings((prev) => ({ ...prev, ...userSettings }))
            navigate(route)
          },
          onClickReturnAndSave: () => {
            setSettings((prev) => ({
              ...prev,
              modal: { ...prev.modal, isModalOpen: false },
            }))
          },
        },
      }))
    } else {
      navigate(route)
    }
    closeSidebar()
  }

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {mode === 'light' ? <BlackSmallIcon /> : <WhiteSmallIcon />}
        <Typography level="title-lg">Tradefulness</Typography>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>
      <Input
        size="sm"
        startDecorator={<SearchRoundedIcon />}
        placeholder="Search"
      />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {/*  TODO: uncomment after implementation*/}
          {/*<ListItem>*/}
          {/*<ListItemButton*/}
          {/*  selected={location.pathname === ROUTES.DASHBOARD}*/}
          {/*  onClick={() => handleClickSidebarLink(ROUTES.DASHBOARD)}*/}
          {/*>*/}
          {/*  <DashboardRoundedIcon />*/}
          {/*  <ListItemContent>*/}
          {/*    <Typography level="title-sm">Dashboard</Typography>*/}
          {/*  </ListItemContent>*/}
          {/*</ListItemButton>*/}
          {/*</ListItem>*/}
          <ListItem>
            <ListItemButton
              selected={location.pathname === ROUTES.CHAT}
              onClick={() => handleClickSidebarLink(ROUTES.CHAT)}
            >
              <QuestionAnswerRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Chat</Typography>
              </ListItemContent>
              {chatHistory.messages.length === 0 && (
                <Chip size="sm" color="primary" variant="solid">
                  {' '}
                  1{' '}
                </Chip>
              )}
            </ListItemButton>
          </ListItem>
          {/*  TODO: uncomment after implementation*/}
          {/*<ListItem>*/}
          {/*  <ListItemButton*/}
          {/*    selected={location.pathname === ROUTES.JOURNAL}*/}
          {/*    onClick={() => handleClickSidebarLink(ROUTES.JOURNAL)}*/}
          {/*  >*/}
          {/*    <MenuBookIcon />*/}
          {/*    <ListItemContent>*/}
          {/*      <Typography level="title-sm">Journal</Typography>*/}
          {/*    </ListItemContent>*/}
          {/*  </ListItemButton>*/}
          {/*</ListItem>*/}
          {/*<ListItem>*/}
          {/*  <ListItemButton*/}
          {/*    selected={location.pathname === ROUTES.TRADING_PLAN}*/}
          {/*    onClick={() => handleClickSidebarLink(ROUTES.TRADING_PLAN)}*/}
          {/*  >*/}
          {/*    <AssignmentRoundedIcon />*/}
          {/*    <ListItemContent>*/}
          {/*      <Typography level="title-sm">Trading plan</Typography>*/}
          {/*    </ListItemContent>*/}
          {/*  </ListItemButton>*/}
          {/*</ListItem>*/}
          <ListItem>
            <ListItemButton
              selected={location.pathname === ROUTES.PRACTICES}
              onClick={() => handleClickSidebarLink(ROUTES.PRACTICES)}
            >
              <SelfImprovementIcon />
              <ListItemContent>
                <Typography level="title-sm">Practices</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>

        {isSubscriptionExpirationNotificationShown && (
          <Card
            invertedColors
            variant="soft"
            color="warning"
            size="sm"
            sx={{ boxShadow: 'none' }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography level="title-sm">
                {isValidSubscription
                  ? 'Subscription ends'
                  : 'Subscription finished'}
              </Typography>
              <IconButton
                size="sm"
                onClick={() =>
                  setSubscriptionExpirationNotificationShown(false)
                }
              >
                <CloseRoundedIcon />
              </IconButton>
            </Stack>
            <Typography level="body-xs" sx={{ mr: 0.5, mb: 1 }}>
              {isValidSubscription
                ? "Your subscription is about to expire, don't forget to renew it"
                : 'Your subscription has finished, choose a new one'}
            </Typography>
            {isValidSubscription && (
              <LinearProgress
                variant="outlined"
                value={80}
                determinate
                sx={{ mb: 1 }}
              />
            )}
            <Button
              size="sm"
              variant="solid"
              onClick={() => {
                closeSidebar()
                navigate(ROUTES.PAYMENT)
              }}
            >
              Choose plan
            </Button>
          </Card>
        )}

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
          }}
        >
          <ListItem>
            <ListItemButton
              component={NavLink}
              to={'https://forms.gle/6WEeycUi8sC7qDo88'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SupportRoundedIcon />
              Support
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={NavLink}
              to={ROUTES.SETTINGS}
              selected={location.pathname === ROUTES.SETTINGS}
              onClick={() => handleClickSidebarLink(ROUTES.SETTINGS)}
            >
              <SettingsRoundedIcon />
              Settings
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <SocialNetworks />
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar variant="outlined" size="sm" src={userSettings.picture} />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{userSettings.name.value}</Typography>
        </Box>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          onClick={() => logout(socket)}
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  )
}

export default Sidebar
