import { Stack, Typography } from '@mui/joy'
import { formatCamelCaseToHumanReadable } from '../../../utils'
import { quizQuestions } from '../../../../quiz/const'
import { TradingProfileCardItem } from './TradingProfileCardItem'
import { UserProfileAnswersType } from './TradingProfileCard.hooks'
import { SelectAnswerType } from './TradingProfileCard'

export const TradingProfileSection = ({
  sectionTitle,
  sectionFields,
  answers,
  handleSelectAnswer,
}: {
  sectionTitle: string
  sectionFields: Record<string, string>
  answers: UserProfileAnswersType
  handleSelectAnswer: SelectAnswerType
}) => {
  return (
    <Stack sx={{ width: '100%', gap: '2rem' }}>
      <Typography level="title-md">
        {formatCamelCaseToHumanReadable(sectionTitle)}
      </Typography>
      <Stack spacing={1} sx={{ width: '100%', gap: '1rem' }} key={sectionTitle}>
        {Object.entries(sectionFields).map(([key, value]) => {
          const quizQuestion = quizQuestions.find((q) => q.id === key)
          if (!quizQuestion) return null

          const selectOptions = [...quizQuestion.answers!]
          let savedAnswer = quizQuestion.isMultiple
            ? answers?.[sectionTitle]?.[key]?.split(', ')
            : answers?.[sectionTitle]?.[key]

          let otherValue = ''

          if (quizQuestion.isOthers) {
            selectOptions.push({
              value: 'other',
              label: 'Other',
            })

            if (
              typeof savedAnswer === 'string' &&
              !selectOptions.some((option) => option.value === savedAnswer)
            ) {
              otherValue = savedAnswer
              savedAnswer = 'other'
            } else if (quizQuestion?.isMultiple && Array.isArray(savedAnswer)) {
              const newSavedAnswer = savedAnswer.map((answ) => {
                if (!selectOptions.some((option) => option.value === answ)) {
                  otherValue = answ
                  return 'other'
                } else {
                  return answ
                }
              })
              savedAnswer = newSavedAnswer
            }
          }

          return (
            <TradingProfileCardItem
              key={key}
              sectionTitle={sectionTitle}
              title={key}
              selectOptions={selectOptions}
              isMultiple={quizQuestion?.isMultiple}
              value={savedAnswer}
              otherValue={otherValue}
              selectAnswer={handleSelectAnswer}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
