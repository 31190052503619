import * as React from 'react'
import { useRef, useState } from 'react'
import Box from '@mui/joy/Box'
import LoginHeader from '../../components/LoginHeader'
import Typography from '@mui/joy/Typography'
import QuizScreens from './QuizScreens'
import { quizQuestions, quizTradingProfile } from './const'
import { TradingProfile } from '../settings/types'

const Quiz: React.FC = () => {
  const userProfile = useRef<TradingProfile>(quizTradingProfile)
  const [currentStep, setCurrentStep] = useState<number>(0)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100dvh',
          width: '100%',
          px: 2,
        }}
      >
        <LoginHeader />
        <Typography
          level="body-sm"
          textAlign="center"
          textColor={
            currentStep === 0 || currentStep === quizQuestions.length - 1
              ? '#FFFFFF'
              : ''
          }
        >
          {`${currentStep} / ${quizQuestions.length - 2}`}
        </Typography>
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
            justifyContent: 'center',
          }}
        >
          <QuizScreens
            quizQuestions={quizQuestions}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            tradingProfile={userProfile.current}
          />
        </Box>
        <Box component="footer" paddingBottom={{ xs: 2, sm: 3 }}>
          <Typography level="body-xs" textAlign="center">
            © Tradefulness AI {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Quiz
