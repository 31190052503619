import React, { createContext, ReactNode, useContext, useState } from 'react'
import { Settings } from '../../types'

const SettingsContext = createContext({
  settings: {} as Settings,
  setSettings: (prev: (prev: Settings) => Settings) => {},
})

export const SettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [settings, setSettings] = useState<Settings>({
    modal: {
      hasUnsavedChanges: false,
      isModalOpen: false,
      title: '',
      subTitle: '',
      leaveWithoutChangesButton: '',
      continueEditButton: '',
      onClickReturnAndSave: () => {},
      onClickLeaveWithoutChangesButton: () => {},
    },
  })

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => {
  return useContext(SettingsContext)
}
