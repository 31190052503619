import { TradingProfile } from '../settings/types'
import { QuizQuestionType } from './types'

export const quizTradingProfile: TradingProfile = {
  personalInsights: {
    resultAssessment: '',
    timeForAnalysisPerDay: '',
    tradingGoal: '',
  },
  riskManagement: {
    maxDrawdown: '',
    riskLimit: '',
    stressFrequency: '',
    stressTolerance: '',
    overconfidenceTolerance: '',
    discipline: '',
  },
  tradingExperience: {
    assets: '',
    experience: '',
    fundamentals: '',
    selfAssessment: '',
  },
  tradingStyle: {
    graphTimeFrame: '',
    planAgility: '',
    style: '',
    tradesPerDay: '',
    tradingDaysPerWeek: '',
  },
}

export const quizQuestions: QuizQuestionType[] = [
  { id: 'intro' } as QuizQuestionType,
  {
    topic: 'tradingExperience',
    id: 'experience',
    question: 'How much time have you been trading?',
    answers: [
      { value: 'Less than 6 months', label: 'Less than 6 months' },
      { value: '6-12 months', label: '6-12 months' },
      { value: '1-3 years', label: '1-3 years' },
      { value: '3-5 years', label: '3-5 years' },
      { value: 'More than 5 years', label: 'More than 5 years' },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'tradingExperience',
    id: 'assets',
    question: 'What types of financial instruments do you primarily trade?',
    answers: [
      { value: 'Stocks', label: 'Stocks' },
      { value: 'Forex', label: 'Forex' },
      { value: 'Commodities', label: 'Commodities' },
      { value: 'Cryptocurrencies', label: 'Cryptocurrencies' },
      { value: 'Options/Futures', label: 'Options/Futures' },
    ],
    isMultiple: true,
    isOthers: true,
  },
  {
    topic: 'tradingExperience',
    id: 'selfAssessment',
    question: 'How would you rate your understanding of technical analysis?',
    answers: [
      { value: 'Beginner', label: 'Beginner' },
      { value: 'Intermediate', label: 'Intermediate' },
      { value: 'Advanced', label: 'Advanced' },
      { value: 'Expert', label: 'Expert' },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'tradingStyle',
    id: 'planAgility',
    question: 'How often do you review and adjust your trading strategy?',
    answers: [
      { value: 'Inflexible', label: 'Rarely' },
      { value: 'A little flexible', label: 'Occasionally' },
      { value: 'Flexible', label: 'Regularly' },
      { value: 'Very flexible', label: 'Constantly' },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'tradingStyle',
    id: 'style',
    question: 'What is your primary trading style?',
    answers: [
      {
        value: 'Scalping',
        label: 'Scalping (holding trades for seconds or minutes)',
      },
      { value: 'Day Trading', label: 'Day Trading (holding trades for a day)' },
      {
        value: 'Swing Trading',
        label: 'Swing Trading (holding trades for days to weeks)',
      },
      {
        value: 'Position Trading',
        label: 'Position Trading (holding trades for weeks to months)',
      },
    ],
    isMultiple: false,
    isOthers: true,
  },
  {
    topic: 'tradingExperience',
    id: 'fundamentals',
    question: 'Which factor most influences your trading decisions?',
    answers: [
      {
        value: 'Technical indicators',
        label: 'Technical indicators',
      },
      { value: 'Fundamental analysis', label: 'Fundamental analysis' },
      {
        value: 'Market news and events',
        label: 'Market news and events',
      },
      {
        value: 'Intuition',
        label: 'Gut feeling or intuition',
      },
    ],
    isMultiple: false,
    isOthers: true,
  },
  {
    topic: 'tradingStyle',
    id: 'tradesPerDay',
    question: 'How many trades do you typically make in a day?',
    answers: [
      {
        value: '0-1',
        label: '0-1',
      },
      { value: '2-5', label: '2-5' },
      {
        value: '6-10',
        label: '6-10',
      },
      {
        value: 'More than 10',
        label: 'More than 10',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'tradingStyle',
    id: 'tradingDaysPerWeek',
    question: 'How many days a week you are typically trading in average?',
    answers: [
      {
        value: '0-1',
        label: '0-1',
      },
      { value: '2-3', label: '2-3' },
      {
        value: '4-5',
        label: '4-5',
      },
      {
        value: '6-7',
        label: '6-7',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'tradingStyle',
    id: 'graphTimeFrame',
    question: 'What time frame do you primarily use for your chart analysis?',
    answers: [
      {
        value: '1-minute to 15-minute',
        label: '1-minute to 15-minute',
      },
      { value: '30-minute to 1-hour', label: '30-minute to 1-hour' },
      {
        value: '4-hour to daily',
        label: '4-hour to daily',
      },
      {
        value: 'Weekly to monthly',
        label: 'Weekly to monthly',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'riskManagement',
    id: 'riskLimit',
    question:
      'What percentage of your trading capital do you risk on a single trade?',
    answers: [
      {
        value: 'Less than 1%',
        label: 'Less than 1%',
      },
      { value: '1-2%"', label: '1-2%' },
      {
        value: '3-5%',
        label: '3-5%',
      },
      {
        value: 'More than 5%',
        label: 'More than 5%',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'riskManagement',
    id: 'maxDrawdown',
    question:
      'What is the maximum drawdown you are comfortable with in your trading account?',
    answers: [
      {
        value: 'Less than 5%',
        label: 'Less than 5%',
      },
      { value: '5-10%', label: '5-10%' },
      {
        value: '10-20%',
        label: '10-20%',
      },
      {
        value: 'More than 20%',
        label: 'More than 20%',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'riskManagement',
    id: 'stressTolerance',
    question: 'How do you typically react to a losing trade?',
    answers: [
      {
        value: 'High',
        label: 'I accept it and move on without much emotion',
      },
      { value: 'Medium', label: 'I feel frustrated but try to stay composed' },
      {
        value: 'Low',
        label: 'I become anxious and overthink my next trades',
      },
      {
        value: 'Very low',
        label: 'I get very upset and may take a break from trading',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'riskManagement',
    id: 'overconfidenceTolerance',
    question: "When you're winning a bunch of trades, what are you doing?",
    answers: [
      {
        value: 'High',
        label: 'I continue to stick to my plan',
      },
      { value: 'Medium', label: 'I trade the same but with more confidence' },
      {
        value: 'Low',
        label: 'I start risking more',
      },
      {
        value: 'Very low',
        label: 'I get cautious and reduce my trading activity',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'riskManagement',
    id: 'stressFrequency',
    question:
      'How often do you experience significant stress or anxiety related to trading?',
    answers: [
      {
        value: 'Rare',
        label: 'Rarely',
      },
      { value: 'Sometimes', label: 'Occasionally' },
      {
        value: 'Often',
        label: 'Frequently',
      },
      {
        value: 'Almost always',
        label: 'Almost always',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'riskManagement',
    id: 'discipline',
    question: 'Do you have a defined risk management plan in place?',
    answers: [
      {
        value: 'High',
        label: 'Yes, and I strictly follow it',
      },
      { value: 'Medium', label: 'Yes, but I sometimes deviate from it' },
      {
        value: 'Low',
        label: 'Yes, but I very often deviate from it',
      },
      {
        value: 'Absent',
        label: 'No, I don’t use a formal risk management plan',
      },
      {
        value: 'Planned',
        label: 'No, but I plan to create one',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'personalInsights',
    id: 'tradingGoal',
    question: 'What is your primary goal in trading?',
    answers: [
      {
        value: 'Additional income',
        label: 'Generate supplementary income',
      },
      {
        value: 'Main income',
        label: 'Making money from trading is the main source of income',
      },
      {
        value: 'Investing',
        label: 'Invest and grow my capital over time',
      },
      {
        value: 'Emotions',
        label: 'Enjoy the thrill and challenge of trading',
      },
    ],
    isMultiple: false,
    isOthers: true,
  },
  {
    topic: 'personalInsights',
    id: 'timeForAnalysisPerDay',
    question:
      'How much time do you spend on trading-related activities daily (including research, analysis, etc.)?',
    answers: [
      {
        value: 'Less than 1 hour',
        label: 'Less than 1 hour',
      },
      {
        value: '1-3 hours',
        label: '1-3 hours',
      },
      {
        value: '4-6 hours',
        label: '4-6 hours',
      },
      {
        value: 'More than 6 hours',
        label: 'More than 6 hours',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  {
    topic: 'personalInsights',
    id: 'resultAssessment',
    question: 'How do you evaluate your trading performance?',
    answers: [
      {
        value: 'Profit/loss statement',
        label: 'By my profit and loss statement',
      },
      {
        value: 'Adherence to trading plan',
        label: 'By my adherence to my trading plan',
      },
      {
        value: 'Risk/reward ratio',
        label: 'By my risk/reward ratio',
      },
      {
        value: 'Trading behavior consistency',
        label: 'By the consistency of my trading behavior',
      },
    ],
    isMultiple: false,
    isOthers: false,
  },
  { id: 'finish' } as QuizQuestionType,
]
