export function openSettingsSidebar() {
  if (typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden'
    document.documentElement.style.setProperty(
      '--SideSettingsNavigation-slideIn',
      '1'
    )
  }
}

export function closeSettingsSidebar() {
  if (typeof window !== 'undefined') {
    document.documentElement.style.removeProperty(
      '--SideSettingsNavigation-slideIn'
    )
    document.body.style.removeProperty('overflow')
  }
}

export function toggleSettingsSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideSettingsNavigation-slideIn')
    if (slideIn) {
      closeSettingsSidebar()
    } else {
      openSettingsSidebar()
    }
  }
}

export const formatCamelCaseToHumanReadable = (input: string) => {
  const words = input.split(/(?=[A-Z])/).map((word) => word.toLowerCase())
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1)
  return words.join(' ')
}

export const emailRegExp = /^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

export const checkRegExpField = (input: string, regExp: RegExp) => {
  return regExp.test(input)
}
