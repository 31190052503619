import * as React from 'react'
import Card from '@mui/joy/Card'
import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Divider from '@mui/joy/Divider'
import Stack from '@mui/joy/Stack'
// import AspectRatio from '@mui/joy/AspectRatio'
// import Avatar from '@mui/joy/Avatar'
// import IconButton from '@mui/joy/IconButton'
// import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import LanguageSelector from './LanguageSelector'
import CardOverflow from '@mui/joy/CardOverflow'
import CardActions from '@mui/joy/CardActions'
import Button from '@mui/joy/Button'
import { Input, FormHelperText } from '@mui/joy'
import { useChangeFormHook, useUnsavedChangesModal } from './AccountCard.hooks'
import { useUserSettings } from '../../../../../components/context/UserSettingsContext'

const AccountCard: React.FC = () => {
  const { userSettings } = useUserSettings()

  const {
    answers,
    handleChangeName,
    handleChangeEmail,
    handleClickSave,
    isFormValid,
    isAnswersChanged,
    setInitialBackup,
  } = useChangeFormHook(userSettings)

  useUnsavedChangesModal(isAnswersChanged)

  React.useEffect(() => {
    setInitialBackup()
  }, [setInitialBackup])

  return (
    <Card sx={{ flex: 1, width: '100%' }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Account info</Typography>
        <Typography level="body-sm">
          Customize your account information.
        </Typography>
      </Box>
      <Divider />
      <Stack
        spacing={3}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          my: 1,
          gap: '1rem',
          alignItems: { xs: 'center', md: 'start' },
        }}
      >
        {/* <Stack
          direction="column"
          spacing={1}
          sx={{ position: 'relative', width: '200px', height: '200px' }}
        >
          <AspectRatio
            ratio="1"
            maxHeight={200}
            sx={{
              flex: 1,
              width: '200px',
              height: '200px',
              borderRadius: '50%',
            }}
          >
            <Avatar variant="outlined" size="sm" src={userSettings.picture} />
          </AspectRatio>
          <IconButton
            aria-label="upload new picture"
            size="sm"
            variant="outlined"
            color="neutral"
            sx={{
              bgcolor: 'background.cards',
              position: 'absolute',
              zIndex: 20,
              borderRadius: '50%',
              right: 10,
              bottom: 10,
              boxShadow: 'sm',
            }}
          >
            <EditRoundedIcon />
          </IconButton>
        </Stack> */}
        <Stack spacing={2} sx={{ flexGrow: 1, width: '100%' }}>
          <FormControl error={!answers.name.isValid}>
            <FormLabel>Name</FormLabel>
            <Input
              value={answers.name.value}
              placeholder="Your name"
              onChange={handleChangeName}
              sx={{ boxShadow: 'none' }}
              required={true}
            />
            <FormHelperText>{answers.name.message}</FormHelperText>
          </FormControl>
          <FormControl error={!answers.email.isValid} disabled={true}>
            <FormLabel>Email</FormLabel>
            <Input
              value={answers.email.value}
              placeholder="Your email"
              onChange={handleChangeEmail}
              sx={{ boxShadow: 'none' }}
              required={true}
            >
              {' '}
              <EmailRoundedIcon sx={{ mr: 1, fontSize: 'small' }} />
            </Input>
            <FormHelperText>{answers.email.message}</FormHelperText>
          </FormControl>
          <LanguageSelector />
        </Stack>
      </Stack>
      <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
        <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
          <Button
            size="sm"
            variant="solid"
            disabled={!isFormValid || !isAnswersChanged}
            onClick={handleClickSave}
          >
            Save
          </Button>
        </CardActions>
      </CardOverflow>
    </Card>
  )
}

export default AccountCard
